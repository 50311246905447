import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 550px;
  height: auto;
  max-height: 100%;
  padding-right: 10px;
  overflow-y: scroll;

  h2 {
    text-align: center;
    line-height: 1.5em;
    letter-spacing: 0.5px;

    font-size: ${({ theme }) => theme.styles.font.large};
    color: ${({ theme }) => theme.styles.colors.blue.bold};
  }

  h3 {
    text-align: center;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    margin-bottom: 15px;

    font-size: ${({ theme }) => theme.styles.font.medium};
    color: ${({ theme }) => theme.styles.colors.blue.bold};
  }
`;

export const Paragraph = styled.p`
  line-height: 1.5em;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
  font-weight: lighter;

  font-size: ${({ theme }) => theme.styles.font.default};
  color: ${({ theme }) => theme.styles.colors.black.opacity};
`;

export const Span = styled.span`
  line-height: 1.5em;
  letter-spacing: 0.5px;
  font-weight: normal;

  font-size: ${({ theme }) => theme.styles.font.default};
  color: ${({ theme }) => theme.styles.colors.black.normal};
`;
