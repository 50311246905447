import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { notificationChannel } from '../../../../../infra/http/web-service';
import Input from '../../../../components/input';
import TextArea from '../../../../components/text-area';
import { updateConfigurationNotificationSchema } from '../../../../../validation/forms';

export type ModelDto = {
  isOpen: boolean;
  notification: any;
  onClose: () => void;
  reload: () => void;
};

const ModalComponentUpdate: React.FC<ModelDto> = ({
  isOpen,
  notification,
  onClose,
  reload,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(updateConfigurationNotificationSchema),
  });

  const onSubmit = async (value: any) => {
    try {
      setLoading(true);
      const { data } = await notificationChannel.update(
        notification.external_id,
        {
          ...value,
          alias: notification.alias,
        }
      );

      toast.success(data.message);
      reset();
      reload();
      onClose();
    } catch (error) {
      toast.error('Ops, não foi possível atualizar a notificação');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!notification) {
      onClose();
    }
  }, []);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Atualizar notificação</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Título da notificação"
              type="text"
              {...register('title')}
              error={errors.title}
            />

            <TextArea
              label="Corpo da mensagem"
              {...register('body')}
              error={errors.body}
            />

            <Button type="submit" colorScheme="blue" isLoading={loading}>
              Atualizar
            </Button>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponentUpdate;
