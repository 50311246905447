import styled from 'styled-components';

export const SalesContainer = styled.div`
  width: 48%;
  height: auto;
  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: ${({ theme }) => theme.styles.spacing.base};

  padding: 20px;

  display: flex;
  flex-direction: column;

  .title {
    width: 100%;
    overflow-y: scroll;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 20px;
    width: 100%;
    height: auto;

    .title {
      overflow-y: unset;
    }
  }
`;

export const Div = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0;

  img {
    width: 50px;
    height: 50px;
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
  }
`;
