import React, { useState, useCallback, useEffect } from 'react';
import { RiInboxArchiveFill } from 'react-icons/ri';
import { Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import Input from '../../../components/input';
import Select from '../../../components/select';
import Switch from '../../../components/switch';
import TextArea from '../../../components/text-area';
import {
  Container,
  Header,
  Form,
  Divisor,
  Box,
  File,
  TypeContainer,
} from './styled';
import { convertIntoBase64 } from '../../../../utils/file';
import { createProductSchema } from '../../../../validation/forms/products/create-product';
import { listProductType } from '../../../../data/usecases/product-type/list-all-product-type';
import ModalComponent from './modal';
import { createProductType } from '../../../../data/usecases/product-type/create-product-type';
import { createImage } from '../../../../data/usecases/image/create-image';
import { listProductById } from '../../../../data/usecases/products/list-by-id-product';
import { updateProduct } from '../../../../data/usecases/products/update-product';

export type Props = {
  match: {
    params: {
      id: string;
    };
  };
};

const UpdateProduct: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const productType = useAppSelector((state) => state.productType) as any;
  const product = useAppSelector((state) => state.product) as any;

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [productTypeName, setProductType] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(createProductSchema),
  });

  const onFile = useCallback(
    async (file: React.ChangeEvent<HTMLInputElement>) => {
      const base64 = await convertIntoBase64(file.target.files);
      setValue('image', file.target.files?.[0]);
      setImage(base64 as string);
    },
    [image]
  );

  const onCreateProduct = () => {
    dispatch(createProductType({ name: productTypeName }));
    onClose();
  };

  const onSubmit = async (value: any) => {
    setLoading(true);
    const getOrCreateImage = async (imageFile: FileList | any) => {
      if (imageFile instanceof FileList) {
        return product.product.image.url;
      }

      const url = await createImage(imageFile);

      return url;
    };

    const url = await getOrCreateImage(value.image);

    if (!url) {
      return;
    }

    dispatch(
      updateProduct(
        props.match.params.id,
        {
          ...value,
          image: url,
        },
        history
      )
    );

    setLoading(false);
  };

  useEffect(() => {
    if (product.product) {
      setValue('name', product.product.product.name);
      setValue('value', product.product.product.value);
      setValue('description', product.product.product.description);
      setValue('product_type_id', product.product.type.external_id);
      setValue('link', product.product.product.link);
      setValue('enabled', product.product.product.enabled);
    }
  }, [product.product]);

  useEffect(() => {
    dispatch(listProductType());

    if (!product.product) {
      dispatch(
        listProductById({
          id: props.match.params.id,
        })
      );
    }
  }, []);

  return (
    <Container>
      <Header>
        <Head title="Atualizar produto" icon={<RiInboxArchiveFill />} />
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Divisor width="90%" scroll>
          <Box scroll={false}>
            <File>
              <label htmlFor="image">
                {image ? (
                  <img src={image} alt="Logo do produto" />
                ) : (
                  <img
                    src={product?.product?.image?.url}
                    alt="Logo do produto"
                  />
                )}

                <input
                  id="image"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  {...register('image')}
                  onChange={onFile}
                />
              </label>
            </File>
          </Box>

          <Box scroll>
            <Input
              label="Nome"
              type="text"
              {...register('name')}
              error={errors.name}
            />

            <TextArea
              label="Descrição"
              {...register('description')}
              error={errors.description}
            />

            <Input
              label="Link de acesso"
              type="text"
              {...register('link')}
              error={errors.link}
            />
            <Input
              label="Preço de venda"
              type="number"
              placeholder="Colocar somente o número (exemplo: 5990 para R$59,90)"
              {...register('value')}
              error={errors.value}
            />

            <TypeContainer>
              <Select
                label="Tipo"
                {...register('product_type_id')}
                error={errors.product_type_id}
                items={productType?.data ? productType.data : []}
              />
              <IconButton
                variant="outline"
                colorScheme="blue"
                aria-label="Add"
                fontSize="20px"
                icon={<MdAdd />}
                onClick={onOpen}
              />
            </TypeContainer>

            {product?.product && (
              <Switch
                label="Deseja habilitar no aplicativo?"
                {...register('enabled')}
                error={errors.enabled}
                enabled={product?.product?.product?.enabled}
              />
            )}
          </Box>
        </Divisor>

        <Divisor width="10%" scroll={false}>
          <Box scroll={false}>
            <Button isLoading={loading} colorScheme="blue" type="submit">
              Atualizar
            </Button>
          </Box>
        </Divisor>
      </Form>

      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        setProductType={setProductType}
        onCreateProduct={onCreateProduct}
      />
    </Container>
  );
};

export default UpdateProduct;
