import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { ImBooks } from 'react-icons/im';
import { BiSolidRightArrow, BiSolidLeftArrow } from 'react-icons/bi';
import {
  Button,
  Skeleton,
  Stack,
  Select,
  Text,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import {
  Container,
  Header,
  FiltersContainer,
  FilterItems,
  Body,
  Card,
  CardBody,
  CardFooter,
  Paragraph,
  Footer,
  Image,
} from './styled';
import { formatCash } from '../../../../utils';
import { listProducts } from '../../../../data/usecases/products/list-all-product';

const Products: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.product);
  const [status, setStatus] = useState('active');

  useEffect(() => {
    listAllProducts(0);
  }, []);

  const pushToDetails = (id: string) => {
    history.push(`/estoques/detalhes/${id}`);
  };

  const listAllProducts = (page: number) => {
    dispatch(listProducts({ page, limit: 10, status }));
  };

  const setNewStatus = (event: any) => {
    setStatus(event.target.value);
    dispatch(listProducts({ page: 0, limit: 10, status: event.target.value }));
  };

  return (
    <Container>
      <Header>
        <Head title="Meus produtos" icon={<ImBooks />} />

        <FiltersContainer>
          <FilterItems>
            <p>Filtrar por:</p>
            <Select
              defaultValue="active"
              variant="filled"
              onChange={setNewStatus}
            >
              <option value="active">Ativado</option>
              <option value="disable">Desativado</option>
            </Select>
          </FilterItems>

          <Button
            leftIcon={<MdAdd />}
            ringColor="blue.500"
            colorScheme="blue"
            variant="ghost"
            onClick={() => {
              history.push('/estoques/cadastro');
            }}
          >
            Adicionar
          </Button>
        </FiltersContainer>
      </Header>
      <Body>
        {products.data ? (
          products.data.map((value: any) => (
            <Card key={value.products.external_id}>
              <Image src={value.image.url} alt={value.products.name} />

              <Stack width="100%">
                <CardBody>
                  <Paragraph>{value.products.name}</Paragraph>

                  <Text py="2">{formatCash(+value.products.value / 100)}</Text>
                </CardBody>

                <CardFooter>
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => pushToDetails(value.products.external_id)}
                  >
                    Visualizar
                  </Button>
                </CardFooter>
              </Stack>
            </Card>
          ))
        ) : (
          <Stack spacing="20px">
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton
                borderRadius="md"
                key={index}
                startColor="blue.500"
                endColor="blue.800"
                height="20px"
              />
            ))}
          </Stack>
        )}
      </Body>
      <Footer>
        <Paragraph>Items: {products?.pagination?.total_items || 0}</Paragraph>

        <ButtonGroup>
          <IconButton
            icon={<BiSolidLeftArrow />}
            colorScheme="blue"
            aria-label="Left"
            disabled={products?.pagination?.current_page <= 0}
            onClick={() =>
              listAllProducts(products.pagination.current_page - 1)
            }
          />

          <IconButton
            icon={<BiSolidRightArrow />}
            colorScheme="blue"
            aria-label="Right"
            disabled={
              products?.pagination?.current_page >=
              products?.pagination?.limit_page
            }
            onClick={() =>
              listAllProducts(products.pagination.current_page + 1)
            }
          />
        </ButtonGroup>
      </Footer>
    </Container>
  );
};

export default Products;
