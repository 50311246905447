import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  LIST_ALL_MESSAGES,
  FETCH_MESSAGE,
} from '../../../infra/redux/messages/constants';
import { message } from '../../../infra/http/web-service';

export const listMessages = (params: any) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_MESSAGE });
  try {
    const { data } = await message.listAll(params);

    dispatch({
      type: LIST_ALL_MESSAGES,
      payload: data.payload,
    });
  } catch (error: any) {
    requestError(error);
  } finally {
    dispatch({ type: FETCH_MESSAGE });
  }
};
