import React, { useEffect, useState } from 'react';
import { Stat, StatNumber, StatHelpText, IconButton } from '@chakra-ui/react';

import { toast } from 'react-toastify';
import FileDownload from 'js-file-download';
import { IoMdDownload } from 'react-icons/io';

import { Container, Card, Icon } from './styled';

import users from '../../../../assets/icons/pray.png';
import courses from '../../../../assets/icons/courses.png';
import store from '../../../../assets/icons/store.png';
import { graph, reports } from '../../../../infra/http/web-service';

type LooseParams = {
  [params: string]: any;
};

const Details: React.FC = () => {
  const [data, setData] = useState<any>({
    users: 0,
    products: 0,
    courses: 0,
  });

  const downloadUsers = async () => {
    try {
      const response = await reports.users();

      FileDownload(response.data, 'usuarios-cadastrados.csv', 'utf-8');
    } catch (error) {
      toast.error(
        'Ops, não conseguimos baixar a lista de usuários cadastrados no sistema!'
      );
    }
  };

  const downloadProducts = async () => {
    try {
      const response = await reports.products();

      FileDownload(response.data, 'produtos-acessados.csv', 'utf-8');
    } catch (error) {
      toast.error('Ops, não conseguimos baixar a lista de acessos aos livros');
    }
  };

  const downloadEvents = async () => {
    try {
      const response = await reports.events();

      FileDownload(response.data, 'eventos-acessados.csv', 'utf-8');
    } catch (error) {
      toast.error('Ops, não conseguimos baixar a lista de acessos aos eventos');
    }
  };

  const json: LooseParams = {
    users: {
      icon: <img src={users} alt="Usuários" />,
      name: 'Usuário ativos',
      download: downloadUsers,
    },
    products: {
      icon: <img src={store} alt="Usuários" />,
      name: 'Livros ativos',
      download: downloadProducts,
    },
    courses: {
      icon: <img src={courses} alt="Usuários" />,
      name: 'Eventos ativos',
      download: downloadEvents,
    },
  };

  const listHome = async () => {
    try {
      const response = await graph.listHome();

      if (!response.data?.payload?.data) {
        return;
      }

      setData(response.data.payload.data);
    } catch (error) {
      toast.error('Ops, não conseguimos buscar a quantidade de mensagens');
    }
  };

  useEffect(() => {
    listHome();
  }, []);

  return (
    <Container>
      {Object.keys(data).map((value, number) => (
        <Card key={number}>
          <Icon>{json[value]?.icon || <img src={users} alt="Usuários" />}</Icon>
          <Stat>
            <StatNumber>{data[value]}</StatNumber>
            <StatHelpText>{json[value]?.name}</StatHelpText>
          </Stat>

          <IconButton
            onClick={json[value]?.download}
            icon={<IoMdDownload />}
            aria-label="download"
            size="md"
          />
        </Card>
      ))}
    </Container>
  );
};

export default Details;
