import React, { memo, useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import Select from '../../../../components/select';
import TextArea from '../../../../components/text-area';

type ModelParams = {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  errors: FieldErrors<any>;
  state: {
    first: 'status' | 'special' | string;
  };
};

const FiltersContainer: React.FC<ModelParams> = ({
  register,
  setValue,
  state,
  errors,
}) => {
  const [isBatch, setIsBatch] = useState(false);

  useEffect(() => {
    setIsBatch(false);
    setValue('configs.user.status', null);
    setValue('configs.user.special', null);
  }, [state]);

  const onChange = (event: any) => {
    if (event?.target?.value !== 'batch') {
      setIsBatch(false);
      return;
    }

    setIsBatch(true);
  };

  if (state.first === 'status') {
    return (
      <Select
        label="Selecione o status"
        {...register('configs.user.status')}
        items={[
          { externalId: 'active', name: 'Ativo' },
          { externalId: 'disable', name: 'Desabilitado' },
        ]}
      />
    );
  }

  if (state.first === 'special') {
    return (
      <>
        <Select
          label="Selecione a condição"
          {...register('configs.user.special')}
          items={[
            { externalId: 'birthday', name: 'Aniversariantes' },
            { externalId: 'batch', name: 'Lista de usuários' },
          ]}
          onChange={onChange}
        />

        {isBatch && (
          <>
            <TextArea
              label="Informe os e-mails"
              {...register('batch')}
              placeholder="email1@gmail.com;email2@gmail.com"
              error={errors.batch}
            />
            Coloque a lista de e-mails, separando cada um por &quot;;&quot;
          </>
        )}
      </>
    );
  }

  return <div />;
};

export default memo(FiltersContainer);
