import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  FETCH_CHANNELS,
  REGISTER_VIDEOS,
} from '../../../infra/redux/channels/constants';
import { channels } from '../../../infra/http/web-service';

export const registerVideos =
  (id: string, onClose: () => void) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CHANNELS });
    try {
      const { data } = await channels.register(id);

      dispatch({ type: REGISTER_VIDEOS, payload: {} });

      toast.success(data.message);
      onClose();
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_CHANNELS });
    }
  };
