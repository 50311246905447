import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  LIST_ALL_PRODUCT_TYPE,
  FETCH_PRODUCT_TYPE,
} from '../../../infra/redux/products-type/constants';
import { productType } from '../../../infra/http/web-service';

export const listProductType = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_PRODUCT_TYPE });
  try {
    const { data } = await productType.listAll();

    dispatch({
      type: LIST_ALL_PRODUCT_TYPE,
      payload: data.payload,
    });
  } catch (error: any) {
    requestError(error);
  } finally {
    dispatch({ type: FETCH_PRODUCT_TYPE });
  }
};
