import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  LIST_MESSAGE_BY_ID,
  FETCH_MESSAGE,
} from '../../../infra/redux/messages/constants';
import { message } from '../../../infra/http/web-service';

export const listMessageById = (params: any) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_MESSAGE });
  try {
    const { data } = await message.listById(params.id);

    dispatch({
      type: LIST_MESSAGE_BY_ID,
      payload: data.payload,
    });
  } catch (error: any) {
    requestError(error);
  } finally {
    dispatch({ type: FETCH_MESSAGE });
  }
};
