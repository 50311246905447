import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { LoginType } from '../../../domain/forms/login-form';
import Input from '../../components/input';
import * as Button from '../../components/button';
import { Container, Form, Paragraph, Span } from './styled';
import { authenticationSchema } from '../../../validation/forms';
import { auth, user } from '../../../infra/http/external-web-service';
import { requestError } from '../../../utils/error';

const Login: React.FC = () => {
  const [isEnableExcludeAccount, setIsEnableExcludeAccount] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginType>({
    resolver: yupResolver(authenticationSchema),
  });

  const [showPassword, setShowPassword] = useState(true);

  const onSubmit = async (value: LoginType) => {
    try {
      setLoading(true);

      const { data: response } = await auth.authentication(value);

      const token = response.payload.access_token;

      await user.delete({
        authorization: `Bearer ${token}`,
      });

      toast.success('Sua conta foi excluída com sucesso!');
    } catch (error: any) {
      requestError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>Exclusão de conta</h2>
        <h3>Aplicativo Andando no profético</h3>

        <Paragraph>
          A exclusão da conta é permanente, lembre-se que você não poderá
          reativar sua conta ou recuperar o conteúdo ou as informações
          adicionadas.
        </Paragraph>
        <Paragraph>
          Se ainda desejar que sua conta seja excluída, informe as informações
          abaixo:
        </Paragraph>

        <Input
          label="E-mail"
          type="text"
          {...register('email')}
          error={errors.email}
        />

        <Input
          label="Senha"
          type={showPassword ? 'password' : 'text'}
          {...register('password')}
          error={errors.password}
          icon={
            showPassword ? (
              <IoMdEyeOff onClick={handleShowPassword} />
            ) : (
              <IoMdEye onClick={handleShowPassword} />
            )
          }
        />

        <Span>
          Digite a palavra <b>&quot;excluir&quot;</b> para confirmar o
          cancelamento
        </Span>
        <Input
          type="text"
          name="confirm"
          onChange={(event: any) =>
            setIsEnableExcludeAccount(event.target.value !== 'excluir')
          }
          placeholder="excluir"
        />

        <Button.Primary
          type="submit"
          label="Excluir sua conta"
          backgroundColor="red"
          color="white"
          disabled={isEnableExcludeAccount}
          isLoading={isLoading}
        />
      </Form>
    </Container>
  );
};

export default Login;
