import { ActionsType } from '../../../domain/redux/action';
import { EventsReducer } from '../../../domain/redux/reducers';
import {
  LIST_ALL_EVENTS,
  CLEAN_EVENTS,
  CREATE_EVENTS,
  FETCH_EVENTS,
  LIST_EVENTS_BY_ID,
  DELETE_EVENTS,
  UPDATE_EVENTS,
} from './constants';

const INITIAL_STATE = {
  isFetch: false,
} as EventsReducer;

const eventsReducer = (state = INITIAL_STATE, action: ActionsType) => {
  switch (action.type) {
    case LIST_ALL_EVENTS:
      return {
        ...action.payload,
        isFetch: state.isFetch,
      };
    case DELETE_EVENTS:
      return {
        ...action.payload,
        isFetch: state.isFetch,
      };
    case CREATE_EVENTS:
      return {};
    case UPDATE_EVENTS:
      return {
        ...action.payload,
        isFetch: state.isFetch,
      };
    case LIST_EVENTS_BY_ID:
      return {
        events: action.payload,
        isFetch: state.isFetch,
      };
    case CLEAN_EVENTS:
      return INITIAL_STATE;
    case FETCH_EVENTS:
      return {
        ...state,
        isFetch: !state.isFetch,
      };
    default:
      return state;
  }
};

export { eventsReducer };
