import * as yup from 'yup';
import { number, string, url, uuid } from '../..';

export const createProductSchema = yup.object().shape({
  name: string('O nome')
    .max(45, 'O nome pode que ter no máximo 45 caracteres')
    .required('O nome é obrigatório'),
  description: string('A descrição')
    .max(500, 'A descrição pode que ter no máximo 500 caracteres')
    .required('A descrição é obrigatória'),
  value: number('O valor').required('O valor é obrigatório'),
  link: url.required('O link de compra é obrigatório'),
  enabled: yup.boolean().required('O status é obrigatório'),
  product_type_id: uuid('O tipo de produto').required(
    'O tipo de produto é obrigatório'
  ),
});
