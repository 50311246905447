import React, { useEffect, useState } from 'react';

import { Text } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SalesContainer, Div } from './styled';
import { graph } from '../../../../infra/http/web-service';

const Sales: React.FC = () => {
  const [data, setData] = useState([]);

  const listChannels = async () => {
    try {
      const response = await graph.listChannel();

      if (!response.data?.payload?.data.length) {
        return;
      }

      setData(response.data.payload.data);
    } catch (error) {
      toast.error('Ops, não conseguimos buscar a quantidade de canais');
    }
  };

  useEffect(() => {
    listChannels();
  }, []);

  return (
    <SalesContainer>
      <div>
        <Text fontSize="larger" fontWeight="bold">
          Canais
        </Text>
      </div>

      <div className="title">
        {data.map((value: any, index) => (
          <div key={index}>
            <Div>
              <img src={value.banner} alt={value.name} />
              <div className="items">
                <Text fontSize="medium" fontWeight="light">
                  {value.name}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  Total de vídeos: {value.quantity}
                </Text>
              </div>
            </Div>
            <hr />
          </div>
        ))}
      </div>
    </SalesContainer>
  );
};

export default Sales;
