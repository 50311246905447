import React, { useEffect, useState } from 'react';
import FileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import * as Button from '../../../components/button';
import { Container, Main } from './styled';
import { form, reports } from '../../../../infra/http/web-service';
import Select from '../../../components/select';

const Forms: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formName, setFormName] = useState<string>('nan');

  const list = async () => {
    try {
      const response = await form.list();

      if (!response.data?.payload) {
        return;
      }

      setData([
        { externalId: 'nan', name: 'Selecione uma opção' },
        ...response.data.payload.map((value: any) => ({
          externalId: value.alias,
          name: value.name,
        })),
      ]);
    } catch (error) {
      toast.error('Ops, não conseguimos buscar os formulários');
    }
  };

  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const formEventValue = event.target.value;

    setFormName(formEventValue);
    setIsDisabled(formEventValue === 'nan');
  };

  const onSubmit = async () => {
    try {
      const response = await reports.forms(formName);

      FileDownload(response.data, `${formName}.csv`, 'utf-8');
    } catch (error) {
      toast.error('Ops, não conseguimos baixar a lista de acessos aos eventos');
    }
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <Container>
      <Main>
        <div className="divisor">
          <h3>
            Selecione qual o formulário que deseja visualizar as resposta, e
            baixe o csv.
          </h3>
        </div>

        <div className="divisor">
          <Select
            label="Formulários"
            onChange={onChangeSelect}
            items={data || []}
          />

          <Button.Primary
            type="button"
            color="white"
            backgroundColor="blue"
            label="Baixar envios"
            disabled={isDisabled}
            onClick={onSubmit}
          />
        </div>
      </Main>
    </Container>
  );
};

export default Forms;
