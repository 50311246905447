import { isAfter } from 'date-fns';
import * as yup from 'yup';
import { onlyDate, onlyHour, string, stringNullable } from '../..';

const configs = yup.object().shape({
  user: yup.object().shape({
    status: yup
      .string()
      .matches(
        /^active$|^disable$/,
        'O status precisa ser "ativo" ou "inativo"'
      )
      .nullable(true),
    special: stringNullable.matches(
      /^birthday$|^batch$/,
      'Somente permitido os valores: aniversário ou arquivo'
    ),
  }),
});

export const createNotificationSchema = yup.object().shape({
  name: string('O nome')
    .max(100, 'O nome pode que ter no máximo 100 caracteres')
    .required('O nome é obrigatório'),
  title: string('O título')
    .max(80, 'O título pode que ter no máximo 80 caracteres')
    .required('O título é obrigatório'),
  body: string('O corpo da mensagem')
    .max(300, 'O corpo da mensagem pode que ter no máximo 300 caracteres')
    .required('O corpo da mensagem é obrigatório'),
  day: onlyDate
    .test(
      'is-bigger-than',
      'A data informada tem que ser maior ou igual a hoje',
      function name(value) {
        if (!value) {
          return false;
        }

        const date = new Date(`${value} ${this.parent.hour}`);

        if (date.toString() === 'Invalid Date') {
          return false;
        }

        return isAfter(date, new Date());
      }
    )
    .required('A data é obrigatória'),
  hour: onlyHour.required('A hora é obrigatória'),
  recurrence: yup.boolean().required('A recorrência é obrigatória'),
  configs,
  batch: yup
    .string()
    .trim()
    .matches(
      /^(|([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
      'Os e-mails inseridos estão inválidos'
    ),
});
