import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CreateCalendar, Calendar } from '../../presentation/pages';

const CalendarRoute: React.FC<any> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/`} exact component={Calendar} />

    <Route path={`${match.url}/cadastro`} exact component={CreateCalendar} />
  </Switch>
);

export default CalendarRoute;
