import React, { memo } from 'react';
import { RadioDTO } from '../../../domain/components/radio-dto';

import {
  Container,
  Error,
  Input,
  Paragraph,
  InputContainer,
  Label,
} from './styled';

const RadioComponent = React.forwardRef<HTMLInputElement, RadioDTO>(
  (props, ref) => {
    const { name, label, type, icon, error, items, ...rest } = props;

    return (
      <Container>
        <Paragraph>{label}</Paragraph>

        <InputContainer>
          {items.map((value) => (
            <div key={value.externalId}>
              <Input
                id={value.externalId}
                type="radio"
                name={name}
                value={value.externalId}
                error={!!error}
                ref={ref}
                {...rest}
              />
              <Label htmlFor={value.externalId}>{value.name}</Label>
            </div>
          ))}
        </InputContainer>

        {error && <Error>{error.message}</Error>}
      </Container>
    );
  }
);

export default memo(RadioComponent);
