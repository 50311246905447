import React, { useEffect, useState } from 'react';
import { Stat, StatNumber, StatHelpText } from '@chakra-ui/react';

import { toast } from 'react-toastify';
import { Container, Card, Icon } from './styled';

import youtube from '../../../../assets/icons/youtube.png';
import calendar from '../../../../assets/icons/calendar.png';
import { graph } from '../../../../infra/http/web-service';

const Details: React.FC = () => {
  const [dataNotify, setNotify] = useState<any>({
    count: 0,
    userNotification: 0,
  });
  const [shipments, setShipments] = useState<any>({
    count: 0,
    userNotification: 0,
  });

  const listHome = async () => {
    try {
      const notify = await graph.listChannelNotification();
      const shipment = await graph.listShipments();

      setNotify(notify.data.payload.data);
      setShipments(shipment.data.payload.data);
    } catch (error) {
      toast.error('Ops, não conseguimos buscar a quantidade de mensagens');
    }
  };

  useEffect(() => {
    listHome();
  }, []);

  return (
    <Container>
      <Card>
        <Icon>
          <img src={youtube} alt="Usuários" />
        </Icon>

        <div>
          <h3>Notificações de vídeos</h3>
          <Stat>
            <StatNumber>{dataNotify.count}</StatNumber>
            <StatHelpText>Quantidade enviada</StatHelpText>
          </Stat>

          <Stat>
            <StatNumber>{dataNotify.userNotification}</StatNumber>
            <StatHelpText>Usuários atingidos</StatHelpText>
          </Stat>
        </div>
      </Card>
      <Card>
        <Icon>
          <img src={calendar} alt="Usuários" />
        </Icon>

        <div>
          <h3>Notificações programadas</h3>
          <Stat>
            <StatNumber>{shipments.count}</StatNumber>
            <StatHelpText>Quantidade enviada</StatHelpText>
          </Stat>

          <Stat>
            <StatNumber>{shipments.userNotification || 0}</StatNumber>
            <StatHelpText>Usuários atingidos</StatHelpText>
          </Stat>
        </div>
      </Card>
    </Container>
  );
};

export default Details;
