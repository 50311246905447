import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  svg {
    position: absolute;
    font-size: ${({ theme }) => theme.styles.font.medium};
    right: 10px;
    bottom: 10px;
    color: ${({ theme }) => theme.styles.colors.black.opacity};
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Text = styled.p`
  width: 90%;
  height: auto;
  padding: 5px 0;
  color: ${({ theme }) => theme.styles.colors.black.normal};
  font-size: ${({ theme }) => theme.styles.font.default};

  @media ${({ theme }) => theme.devices.mobileL} {
    height: auto;
    padding: 10px;
  }
`;

export const Error = styled.p`
  position: absolute;
  bottom: -22px;
  color: ${({ theme }) => theme.styles.colors.red.medium};
  font-size: ${({ theme }) => theme.styles.font.small};
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + span {
    background-color: #2196f3;
  }

  input:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 30px;

    :before {
      position: absolute;
      content: '';
      height: 22px;
      width: 22px;
      left: 2px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    span {
      :before {
        left: 0px;
      }
    }
  }
`;
