export const getStepMessage = (message: string) => {
  switch (message) {
    case 'first-notification-live':
      return 'primeira mensagem';
    case 'second-notification-live':
      return 'segunda mensagem';
    case 'third-notification-live':
      return 'terceira mensagem';
    default:
      return 'sem informação';
  }
};
