import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { format } from 'date-fns';
import Paragraph from '../../../components/paragraph';

import { Container, AutoLayout, Badge } from './styled';
import { useAppSelector } from '../../../../data/hooks/redux';
import { getColorMessage, getStringValue } from '../../../../utils/messages';
import { screenPrinting } from '../../../../utils/print';
import ModalComponent from '../modal';
import { formatDate } from '../../../../utils';

export type ModelDto = {
  isOpen: boolean;
  onClose: () => void;
  removeMessage: (id: string) => void;
};

const DrawerContainer: React.FC<ModelDto> = ({
  isOpen,
  onClose,
  removeMessage,
}) => {
  const {
    isOpen: isOpenModal,
    onOpen,
    onClose: onCloseModal,
  } = useDisclosure();
  const messages = useAppSelector((state) => state.messages);

  const onPrint = () => {
    screenPrinting(messages.item?.message?.external_id || 'mensagens');
  };

  const onRemove = () => {
    removeMessage(messages.item?.message?.external_id);
    onCloseModal();
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Informações</DrawerHeader>

          <DrawerBody>
            <div id="print">
              {messages.item && (
                <>
                  <AutoLayout>
                    <h2>Usuário</h2>

                    <Paragraph align="left" fontSize="small" label="Nome">
                      {messages.item?.user?.name}
                    </Paragraph>

                    <Paragraph align="left" fontSize="small" label="E-mail">
                      {messages.item?.user?.email}
                    </Paragraph>

                    <Paragraph
                      align="left"
                      fontSize="small"
                      label="Usário desde:"
                    >
                      {formatDate(messages.item?.user?.created_at)}
                    </Paragraph>
                  </AutoLayout>

                  <AutoLayout>
                    <h2>Mensagem</h2>

                    <Paragraph align="left" fontSize="small" label="Criado em:">
                      {formatDate(messages.item?.message?.created_at)}
                    </Paragraph>

                    <Badge colorType={getColorMessage(messages.item.type.type)}>
                      {getStringValue(messages.item.type.type)}
                    </Badge>

                    <Container>{messages.item?.message?.text}</Container>
                  </AutoLayout>
                </>
              )}
            </div>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <IconButton
              icon={<FaDownload />}
              aria-label="Download da mensagem"
              variant="outline"
              colorScheme="blue"
              onClick={onPrint}
            />
            <Button marginLeft={3} colorScheme="red" onClick={onOpen}>
              Deletar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <ModalComponent
        isOpen={isOpenModal}
        onClose={onCloseModal}
        removeMessage={onRemove}
      />
    </>
  );
};

export default DrawerContainer;
