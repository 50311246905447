import React, { useEffect, useState } from 'react';
import { FaYoutube } from 'react-icons/fa';
import {
  Button,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import TextArea from '../../../components/text-area';
import { Container, Header, Form, Divisor, Box, Example } from './styled';
import { listChannels } from '../../../../data/usecases/channels/list-all-channels';
import { updateChannels } from '../../../../data/usecases/channels/update-channels';
import Paragraph from '../../../components/paragraph';

import icon from '../../../../assets/icon.png';
import { notificationChannel } from '../../../../infra/http/web-service';
import ModalComponentCreate from './modal';
import { getStepMessage } from '../../../../utils/configuration';
import ModalComponentUpdate from './update';
import Switch from '../../../components/switch';

export type Props = {
  match: {
    params: {
      id: string;
    };
  };
};

const UpdateChannels: React.FC<Props> = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const channels = useAppSelector((state) => state.channels);

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState<any>(null);
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
  } = useDisclosure();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const onSubmit = async (value: any) => {
    setLoading(true);

    dispatch(
      updateChannels(
        props.match.params.id,
        {
          notification: value.notification,
          description: value.description || null,
          lives: [],
        },
        history
      )
    );
    setLoading(false);
  };

  const reload = async () => {
    await listNotifications();
  };

  const onUpdateNotification = (notify: any) => {
    setNotification(notify);
    onOpenUpdate();
  };

  const listNotifications = async () => {
    try {
      const { data } = await notificationChannel.listAll(id);

      setNotifications(data.payload.data);
    } catch (error) {
      toast.error('Ops! Não conseguimos listar as notificações');
    }
  };

  useEffect(() => {
    if (!channels?.data?.length) {
      return;
    }

    const foundChannel = channels.data.find(
      (value: any) => value.external_id === props.match.params.id
    );

    setValue('notification', foundChannel?.notification || false);
    setValue('description', foundChannel?.description);
  }, [channels.data]);

  useEffect(() => {
    if (!channels?.data?.length) {
      dispatch(listChannels({ page: 0, limit: 10 }));
    }

    listNotifications();
  }, []);

  return (
    <Container>
      <Header>
        <Head title="Atualização de canal" icon={<FaYoutube />} />
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Divisor width="45%" scroll>
          <Box scroll={false}>
            <Switch
              label="Deseja que envie notificação?"
              {...register('notification')}
              error={errors.notification}
            />
            <TextArea
              label="Escreva a descrição do canal"
              placeholder="Lives todos os dias às 06h30, 19h00 e 22h00"
              {...register('description')}
              error={errors.description}
            />
          </Box>
        </Divisor>

        <Divisor width="10%" scroll={false}>
          <Box scroll={false}>
            <Button isLoading={loading} colorScheme="blue" type="submit">
              Atualizar
            </Button>
          </Box>
        </Divisor>

        <Divisor width="45%" scroll>
          <Box scroll={false}>
            <Paragraph
              align="left"
              fontSize="default"
              label="Minhas notificações"
            >
              Aqui você pode configurar as notificações que serão enviadas para
              os nosso usuários.
            </Paragraph>

            <Popover>
              <PopoverTrigger>
                <Button>Ver tipos</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Tipos de notificações</PopoverHeader>
                <PopoverBody>
                  <ul>
                    <li>
                      A primeira notificação é enviada 30 minutos antes da live;
                    </li>
                    <li>A segunda é enviada no horário agendado do vídeo;</li>
                    <li>
                      A terceira é quando o vídeo é ao vivo sem ter um
                      agendamento de em breve antes.
                    </li>
                  </ul>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            {notifications?.length < 3 && (
              <Button
                isLoading={loading}
                colorScheme="blue"
                type="button"
                onClick={onOpenCreate}
              >
                Criar uma nova
              </Button>
            )}
          </Box>

          <Box scroll>
            {!!notifications.length &&
              notifications.map((notify: any) => (
                <Example
                  key={notify.external_id}
                  onClick={() => onUpdateNotification(notify)}
                >
                  <img src={icon} alt="Logo do aplicativo iracet" />
                  <Paragraph align="left" fontSize="small" label={notify.title}>
                    {notify.body}
                  </Paragraph>

                  <span>{getStepMessage(notify.alias)}</span>
                </Example>
              ))}
          </Box>
        </Divisor>
      </Form>

      <ModalComponentCreate
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
        notifications={notifications}
        channelId={id}
        reload={reload}
      />

      <ModalComponentUpdate
        notification={notification}
        isOpen={isOpenUpdate}
        onClose={onCloseUpdate}
        reload={reload}
      />
    </Container>
  );
};

export default UpdateChannels;
