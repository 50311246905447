import * as yup from 'yup';
import { isAfter } from 'date-fns';
import { frequency, onlyDate, onlyHour, string } from '../..';

export const createDiarySchema = yup.object().shape({
  title: string('O título').required('O título é obrigatório'),
  description: string('A descrição').required('A descrição é obrigatória'),
  day: onlyDate
    .test(
      'is-bigger-than',
      'A data informada tem que ser maior ou igual a hoje',
      function name(value) {
        if (!value) {
          return false;
        }

        const date = new Date(`${value} ${this.parent.hour}`);

        if (date.toString() === 'Invalid Date') {
          return false;
        }

        return isAfter(date, new Date());
      }
    )
    .required('A data é obrigatória'),
  hour: onlyHour.required('A hora é obrigatória'),
  recurrence: yup.boolean().required('A recorrência é obrigatória'),
  local: string('O local').required('O local é obrigatório'),
  frequency,
});
