import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import React from 'react';

export type ModelDto = {
  isOpen: boolean;
  onClose: () => void;
  removeEvents: () => void;
  isLoading: boolean;
};

const ModalComponent: React.FC<ModelDto> = ({
  isOpen,
  isLoading,
  onClose,
  removeEvents,
}) => (
  <Modal isCentered isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Confirmação</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text marginBottom={5}>Deseja realmente deletar esse evento?</Text>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={onClose}>
          Não
        </Button>
        <Button isLoading={isLoading} onClick={removeEvents}>
          Sim
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default ModalComponent;
