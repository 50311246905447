import styled from 'styled-components';
import { Theme } from '../../../../main/style/theme';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Grid = styled.div`
  width: 100%;
  height: calc(100% - 80px);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 20px;

  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-wrap: wrap;
  }
`;

export const Card = styled.div`
  width: 50%;
  height: 100%;
  border-radius: 16px;
  padding: 20px;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    height: auto;
  }
`;

export const QrCodeContainer = styled.div`
  width: 100%;
  height: 80%;
  overflow: hidden;

  section {
    width: 100%;
    height: 100%;
  }

  div {
    padding-top: 0px !important;
  }

  video {
    position: relative !important;
  }
`;

export const Badge = styled.div<{ colorType: keyof Theme['styles']['colors'] }>`
  padding: 10px;
  top: 20px;
  right: 20px;
  border-radius: ${({ theme }) => theme.styles.spacing.short};

  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: 500;
  color: ${({ theme }) => theme.styles.colors.white.normal};
  font-size: ${({ theme }) => theme.styles.font.small};

  background-color: ${({ colorType, theme }) =>
    theme.styles.colors[colorType]?.medium
      ? theme.styles.colors[colorType].medium
      : '#000000'};
`;

export const DrawerContainer = styled.div`
  width: 100%;
  height: auto;

  padding: 20px;
  box-sizing: border-box;

  border: 1px solid ${({ theme }) => theme.styles.colors.gray.light}55;
`;

export const PlatformContainer = styled.div`
  width: 100%;
  height: auto;

  padding: 20px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  gap: 10px;

  img {
    width: 100px;
    object-fit: contain;
  }

  div {
    width: 100%;
  }
`;

export const Table = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  margin-top: 20px;
`;

export const TableItem = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;

  p {
    width: 50%;
    text-overflow: ellipsis;
  }

  .badge {
    max-width: calc(50% - 50px);
  }

  .button {
    width: 50px;
  }
`;
