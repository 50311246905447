import { subDays } from 'date-fns';
import * as yup from 'yup';

type StoryTypes = 'image' | 'text';

export const createStorySchema = yup.object().shape({
  link: yup.string().optional(),
  link_alias: yup
    .string()
    .max(30, 'o campo "link_alias" deve ter no maxímo 30 caracteres')
    .optional(),
  text: yup.string().optional(),
  schedule_at: yup.date().min(subDays(new Date(), 1), 'O agendamento não pode ser feito para uma data menor do que hoje!').optional(),
  type: yup
    .string()
    .oneOf<StoryTypes>(['image', 'text'])
    .required('o campo "type" é obrigatório.'),
});
