import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  gap: 20px;
`;
