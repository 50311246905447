import { requestError } from '../../../utils/error';

import { image } from '../../../infra/http/web-service';

export const createImage = async (file: File) => {
  try {
    const formData = new FormData();

    formData.append('image', file);

    const { data } = await image.create(formData);

    return data.payload.url;
  } catch (error: any) {
    requestError(error);

    return null;
  }
};
