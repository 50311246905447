import styled from 'styled-components';

export const SalesContainer = styled.div`
  width: 30%;
  height: 100%;
  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: ${({ theme }) => theme.styles.spacing.base};
  color: ${({ theme }) => theme.styles.colors.black.normal};
  font-size: ${({ theme }) => theme.styles.font.default};

  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 20px;
    width: 100%;
    height: auto;
  }
`;

export const Div = styled.div`
  width: 100%;

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
`;
