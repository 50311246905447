import styled from 'styled-components';
import { Theme } from '../../../../main/style/theme';

export const Container = styled.div`
  width: 100%;
  height: auto;

  padding: 20px;
  box-sizing: border-box;

  border: 1px solid ${({ theme }) => theme.styles.colors.gray.light}55;
`;

export const AutoLayout = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;

  position: relative;

  h2 {
    line-height: 1.5em;
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: bolder;
    font-size: ${({ theme }) => theme.styles.font.default};
  }
`;

export const Badge = styled.div<{ colorType: keyof Theme['styles']['colors'] }>`
  padding: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: ${({ theme }) => theme.styles.spacing.short};

  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: lighter;
  font-size: ${({ theme }) => theme.styles.font.small};

  background-color: ${({ colorType, theme }) =>
    theme.styles.colors[colorType]?.medium
      ? theme.styles.colors[colorType].medium
      : '#000000'};
`;
