import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Paragraph from '../../../../components/paragraph';

import { AutoLayout, Badge, Example, ShipmentsContainer } from './styled';
import {
  getColorNotification,
  getIconsNotification,
  getSpecialCondition,
  getStringValue,
} from '../../../../../utils/notification';

import icon from '../../../../../assets/icon.png';
import { shipments } from '../../../../../infra/http/web-service';
import ModalComponent from '../modal';

export type ModelDto = {
  isOpen: boolean;
  notification: any;
  onClose: () => void;
  removeNotification: (id: string) => void;
};

const DrawerContainer: React.FC<ModelDto> = ({
  isOpen,
  notification,
  onClose,
  removeNotification,
}) => {
  const {
    isOpen: isOpenModal,
    onOpen,
    onClose: onCloseModal,
  } = useDisclosure();
  const [shipmentsData, setShipments] = useState<any[]>([]);

  const listAllShipments = async () => {
    try {
      const { data } = await shipments.listAll(notification.external_id);

      setShipments(data.payload.data);
    } catch (error) {
      setShipments([]);
    }
  };

  useEffect(() => {
    setShipments([]);

    if (!notification) {
      return;
    }

    if (notification.status !== 'created') {
      listAllShipments();
    }
  }, [notification]);

  const onRemove = () => {
    onCloseModal();
    removeNotification(notification.external_id);
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Notificação</DrawerHeader>

          <DrawerBody>
            <div id="print">
              {notification && (
                <>
                  <AutoLayout>
                    <h2>
                      {notification.name}
                      <Badge
                        colorType={getColorNotification(notification.status)}
                      >
                        <Icon as={getIconsNotification(notification.status)} />
                        {getStringValue(notification.status)}
                      </Badge>
                    </h2>

                    <Paragraph
                      align="left"
                      fontSize="small"
                      label="Agendado para"
                    >
                      {notification.recurrence
                        ? `Todos os dias às ${notification.hour.substring(
                            0,
                            5
                          )}`
                        : `${format(
                            new Date(notification.day),
                            'dd/MM/yyyy'
                          )} às ${notification.hour.substring(0, 5)}`}
                    </Paragraph>

                    <Example>
                      <img src={icon} alt="Logo do aplicativo iracet" />
                      <Paragraph
                        align="left"
                        fontSize="small"
                        label={notification.title}
                      >
                        {notification.body}
                      </Paragraph>
                    </Example>
                  </AutoLayout>

                  <AutoLayout>
                    <h2>Detalhes do envio</h2>

                    {notification.configs.user && (
                      <>
                        {notification.configs.user.status && (
                          <Paragraph
                            align="left"
                            fontSize="small"
                            label="Todos os usuários"
                          >
                            {notification.configs.user.status === 'active'
                              ? 'Ativos'
                              : 'Desativados'}
                          </Paragraph>
                        )}

                        {notification.configs.user.special && (
                          <Paragraph
                            align="left"
                            fontSize="small"
                            label="Condição especial"
                          >
                            {getSpecialCondition(
                              notification.configs.user.special
                            )}
                          </Paragraph>
                        )}
                      </>
                    )}
                  </AutoLayout>

                  <AutoLayout>
                    {!!shipmentsData.length && (
                      <>
                        <h2>Envios</h2>

                        {shipmentsData.map((value) => (
                          <ShipmentsContainer key={value.external_id}>
                            <Paragraph
                              align="left"
                              fontSize="small"
                              label="Quantidade de usuário atingidos"
                            >
                              {value.quantity}
                            </Paragraph>

                            <Paragraph
                              align="left"
                              fontSize="small"
                              label="Data do envio"
                            >
                              {format(
                                new Date(value.created_at),
                                'dd/MM/yyyy HH:mm'
                              )}
                            </Paragraph>
                          </ShipmentsContainer>
                        ))}
                      </>
                    )}
                  </AutoLayout>
                </>
              )}
            </div>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            {notification &&
              ['created', 'recurrence'].includes(notification.status) && (
                <Button marginLeft={3} colorScheme="red" onClick={onOpen}>
                  Cancelar
                </Button>
              )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <ModalComponent
        isLoading={false}
        isOpen={isOpenModal}
        onClose={onCloseModal}
        removeNotification={onRemove}
      />
    </>
  );
};

export default DrawerContainer;
