import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  overflow-y: scroll;

  h2 {
    line-height: 1.5em;
    letter-spacing: 0.5px;
    text-align: center;
    font-weight: 500;

    font-size: ${({ theme }) => theme.styles.font.medium};
    color: ${({ theme }) => theme.styles.colors.blue.bold};
  }

  h2 + h2 {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  ul {
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  li {
    line-height: 1.5em;
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: lighter;

    font-size: ${({ theme }) => theme.styles.font.default};
  }
`;

export const Title = styled.h3`
  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: left;
  text-indent: 20px;
  margin-top: 30px;

  font-size: ${({ theme }) => theme.styles.font.default};
  color: ${({ theme }) => theme.styles.colors.blue.bold};
`;
