import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  width: 100%;

  min-height: 40px;
  height: 6.5vh;
  max-height: 47px;

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Paragraph = styled.p`
  width: 100%;
  height: auto;
  padding: 5px 0;
  color: ${({ theme }) => theme.styles.colors.black.normal};
  font-size: ${({ theme }) => theme.styles.font.default};
`;

export const Label = styled.label`
  width: 100%;
  height: auto;
  padding: 5px 0;
  color: ${({ theme }) => theme.styles.colors.black.normal};
  font-size: ${({ theme }) => theme.styles.font.default};
  margin-left: 10px;
`;

export const Input = styled.input<{ error: boolean }>`
  font-size: ${({ theme }) => theme.styles.font.small};
  border: ${({ error, theme }) => {
    if (error) {
      return `1.5px solid ${theme.styles.colors.red.medium}`;
    }

    return `1.5px solid ${theme.styles.colors.gray.light}`;
  }};
`;

export const Error = styled.p`
  position: absolute;
  bottom: -22px;
  color: ${({ theme }) => theme.styles.colors.red.medium};
  font-size: ${({ theme }) => theme.styles.font.small};
`;
