import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { FaCalendarCheck } from 'react-icons/fa';
import { BiSolidRightArrow, BiSolidLeftArrow } from 'react-icons/bi';
import {
  Button,
  Skeleton,
  Stack,
  Select,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import {
  Container,
  Header,
  FiltersContainer,
  FilterItems,
  Body,
  Card,
  CardBody,
  CardFooter,
  Footer,
  Image,
} from './styled';
import { listEvents } from '../../../../data/usecases/events/list-all-events';
import Paragraph from '../../../components/paragraph';

const Events: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const events = useAppSelector((state) => state.events);
  const [status, setStatus] = useState('active');

  useEffect(() => {
    listAllEvents(0);
  }, []);

  const pushToDetails = (id: string) => {
    history.push(`/eventos/detalhes/${id}`);
  };

  const listAllEvents = (page: number) => {
    dispatch(listEvents({ page, limit: 10, status }));
  };

  const setNewStatus = (event: any) => {
    setStatus(event.target.value);
    dispatch(listEvents({ page: 0, limit: 10, status: event.target.value }));
  };

  return (
    <Container>
      <Header>
        <Head title="Meus eventos" icon={<FaCalendarCheck />} />

        <FiltersContainer>
          <FilterItems>
            <p>Filtrar por:</p>
            <Select
              defaultValue="active"
              variant="filled"
              onChange={setNewStatus}
            >
              <option value="active">Evento Ativo</option>
              <option value="disable">ProphetFlix</option>
            </Select>
          </FilterItems>

          <Button
            leftIcon={<MdAdd />}
            ringColor="blue.500"
            colorScheme="blue"
            variant="ghost"
            onClick={() => {
              history.push('/eventos/cadastro');
            }}
          >
            Novo evento
          </Button>
        </FiltersContainer>
      </Header>
      <Body>
        {events.data ? (
          events.data.map((value: any) => (
            <Card key={value.course.external_id}>
              <Image src={value.image.url} alt={value.course.name} />

              <Stack width="100%">
                <CardBody>
                  <Paragraph
                    align="left"
                    fontSize="default"
                    label={value.course.name}
                  />
                </CardBody>

                <CardFooter>
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => pushToDetails(value.course.external_id)}
                  >
                    Visualizar
                  </Button>
                </CardFooter>
              </Stack>
            </Card>
          ))
        ) : (
          <Stack spacing="20px">
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton
                borderRadius="md"
                key={index}
                startColor="blue.500"
                endColor="blue.800"
                height="20px"
              />
            ))}
          </Stack>
        )}
      </Body>
      <Footer>
        <Paragraph fontSize="default" align="left" label="Items:">
          {events?.pagination?.total_items || 0}
        </Paragraph>

        <ButtonGroup>
          <IconButton
            icon={<BiSolidLeftArrow />}
            colorScheme="blue"
            aria-label="Left"
            disabled={events?.pagination?.current_page <= 0}
            onClick={() => listAllEvents(events.pagination.current_page - 1)}
          />

          <IconButton
            icon={<BiSolidRightArrow />}
            colorScheme="blue"
            aria-label="Right"
            disabled={
              events?.pagination?.current_page >= events?.pagination?.limit_page
            }
            onClick={() => listAllEvents(events.pagination.current_page + 1)}
          />
        </ButtonGroup>
      </Footer>
    </Container>
  );
};

export default Events;
