import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  FETCH_NOTIFICATION,
  CREATE_NOTIFICATION,
} from '../../../infra/redux/notifications/constants';
import { notifications, batch } from '../../../infra/http/web-service';

export const createNotification =
  (params: any, history: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_NOTIFICATION });
    try {
      const { data } = await notifications.create({
        name: params.name,
        day: params.day,
        hour: params.hour,
        recurrence: params.recurrence,
        title: params.title,
        body: params.body,
        configs: params.configs,
      });

      dispatch({ type: CREATE_NOTIFICATION, payload: data.payload });

      if (params.configs?.user?.special === 'batch') {
        await batch.create({
          notification_id: data.payload.notification_id,
          emails: params.batch,
        });
      }

      toast.success(data.message);
      history.go(-1);
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_NOTIFICATION });
    }
  };
