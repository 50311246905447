import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import React from 'react';
import Paragraph from '../../../../components/paragraph';

import { Badge, DrawerContainer, PlatformContainer } from '../styled';
import { getColorStatus, getStatus } from '../../../../../utils/tickets';
import { formatDate } from '../../../../../utils';

export type ModelDto = {
  isOpen: boolean;
  onClose: () => void;
  clear: () => void;
  ticket: any;
  checkInTicket: () => void;
  isLoading: boolean;
};

const DrawerComponent: React.FC<ModelDto> = ({
  isOpen,
  onClose,
  clear,
  ticket,
  checkInTicket,
  isLoading,
}) => {
  const onCloseDrawer = () => {
    clear();
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} placement="right" size="md" onClose={onCloseDrawer}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          {ticket.course ? ticket.course.name : 'Ticket'}
        </DrawerHeader>

        {ticket.participant && (
          <DrawerBody>
            <Paragraph align="left" fontSize="small" label="Ticket:">
              {ticket.participant?.external_id}
            </Paragraph>

            <Paragraph align="left" fontSize="small" label="Nome:">
              {ticket.participant?.name}
            </Paragraph>

            <Paragraph align="left" fontSize="small" label="Documento:">
              {ticket.participant?.document}
            </Paragraph>

            <Paragraph align="left" fontSize="small" label="E-mail:">
              {ticket.participant?.email}
            </Paragraph>

            <Paragraph align="left" fontSize="small" label="Telefone:">
              {ticket.participant?.phone}
            </Paragraph>

            <PlatformContainer>
              <img src={ticket.course.title} alt={ticket.course.name} />

              <div>
                <Paragraph align="left" fontSize="small" label="Produto:">
                  {ticket.platform.name}
                </Paragraph>

                <Paragraph align="left" fontSize="small" label="Plataforma:">
                  {ticket.platform.platform}
                </Paragraph>
              </div>
            </PlatformContainer>

            <DrawerContainer>
              {ticket?.participant?.status === 'check-in' && (
                <Paragraph align="left" fontSize="small" label="Liberado em:">
                  {formatDate(ticket.participant?.checkin_at)}
                </Paragraph>
              )}

              {ticket?.participant?.status === 'cancel' && (
                <>
                  <Paragraph
                    align="left"
                    fontSize="small"
                    label="Cancelado em:"
                  >
                    {formatDate(ticket.participant?.deleted_at)}
                  </Paragraph>

                  <Paragraph align="left" fontSize="small" label="Motivo:">
                    Para entender o motivo é preciso entrar em contato com a
                    plataforma de venda.
                  </Paragraph>
                </>
              )}

              <Badge colorType={getColorStatus(ticket.participant?.status)}>
                {getStatus(ticket.participant?.status)}
              </Badge>
            </DrawerContainer>
          </DrawerBody>
        )}

        <DrawerFooter borderTopWidth="1px">
          <Button onClick={onCloseDrawer}>Cancelar</Button>
          {ticket && ticket?.participant?.status === 'open' && (
            <Button
              marginLeft={3}
              colorScheme="green"
              onClick={checkInTicket}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Check-in
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerComponent;
