import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Skeleton,
  Stack,
  IconButton,
  ButtonGroup,
  useDisclosure,
} from '@chakra-ui/react';

import { BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';
import Head from '../../components/header';
import { Container, Header, Footer, Body } from './styled';
import { formatDate } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../data/hooks/redux';
import { listClients } from '../../../data/usecases/clients/list-all-clients';
import Paragraph from '../../components/paragraph';
import DrawerContainer from './drawer';

const Client: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const clients = useAppSelector((state) => state.clients);

  const [width, setWidth] = useState(0);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    listAllClients(0);
  }, []);

  useLayoutEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const onCloseModal = () => {
    setUserId(null);
    onClose();
  };

  const listDetails = (id: string) => {
    setUserId(id);
    onOpen();
  };

  const listAllClients = (page: number) => {
    dispatch(listClients({ page, limit: 10 }));
  };

  return (
    <Container>
      <Header>
        <Head title="Usuários" icon={<FaUsers />} />
      </Header>

      <Body>
        {clients?.data?.length ? (
          <Table variant="striped" size="md" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>nome</Th>

                {width > 550 && (
                  <>
                    <Th>email</Th>
                    <Th>criado em</Th>
                  </>
                )}

                <Th>ações</Th>
              </Tr>
            </Thead>
            <Tbody bgColor="white">
              {clients?.data.map((value: any) => (
                <Tr key={value.external_id}>
                  <Td>{value.name}</Td>

                  {width > 550 && (
                    <>
                      <Td>{value.email}</Td>
                      <Td>{formatDate(value.created_at)}</Td>
                    </>
                  )}

                  <Td>
                    <Button
                      size="xs"
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => listDetails(value.external_id)}
                    >
                      Visualizar
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Stack spacing="20px">
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton
                borderRadius="md"
                key={index}
                startColor="pink.500"
                endColor="orange.500"
                height="20px"
              />
            ))}
          </Stack>
        )}
      </Body>

      <Footer>
        <Paragraph fontSize="default" align="left" label="Items:">
          {clients?.pagination?.total_items || 0}
        </Paragraph>

        <ButtonGroup>
          <IconButton
            icon={<BiSolidLeftArrow />}
            colorScheme="blue"
            aria-label="Left"
            disabled={clients?.pagination?.current_page <= 0}
            onClick={() => listAllClients(clients.pagination.current_page - 1)}
          />

          <IconButton
            icon={<BiSolidRightArrow />}
            colorScheme="blue"
            aria-label="Right"
            disabled={
              clients?.pagination?.current_page >=
              clients?.pagination?.limit_page
            }
            onClick={() => listAllClients(clients.pagination.current_page + 1)}
          />
        </ButtonGroup>
      </Footer>

      <DrawerContainer isOpen={isOpen} onClose={onCloseModal} userId={userId} />
    </Container>
  );
};

export default Client;
