import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  CREATE_PRODUCT_TYPE,
  FETCH_PRODUCT_TYPE,
} from '../../../infra/redux/products-type/constants';
import { productType } from '../../../infra/http/web-service';

export const createProductType =
  (params: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_PRODUCT_TYPE });
    try {
      const { data } = await productType.create(params);

      dispatch({
        type: CREATE_PRODUCT_TYPE,
        payload: {
          externalId: data.payload.type_id,
          name: params.name.toUpperCase(),
        },
      });

      toast.success(data.message);
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_PRODUCT_TYPE });
    }
  };
