export const getStatus = (status: string) => {
  const object: any = {
    'check-in': 'Check-in já realizado',
    open: 'Em aberto',
    cancel: 'Cancelado',
  };

  return object[status] || 'Sem informação';
};

export const getColorStatus = (status: string) => {
  const object: any = {
    'check-in': 'blue',
    open: 'green',
    cancel: 'red',
  };

  return object[status] || 'blue';
};
