import React from 'react';

import loginImage from '../../../assets/Logo_profeta.png';

import { Container, LeftContainer, RightContainer } from './styled';

const Login: React.FC = ({ children }) => (
  <Container>
    <LeftContainer>
      <img src={loginImage} alt="Home dashboard" />

      <h1>
        Painel administrativo, através deste canal cuidaremos
        do seu aplicativo
      </h1>
    </LeftContainer>
    <RightContainer>{children}</RightContainer>
  </Container>
);

export default Login;
