import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';

import { password } from '../../../infra/http/web-service';

export const resetPassword = async (params: any) => {
  try {
    const { data } = await password.reset(params);

    toast.success(data.message);
  } catch (error: any) {
    requestError(error);
  }
};
