import * as yup from 'yup';
import { string } from '../..';

export const createChannelSchema = yup.object().shape({
  youtubeId: string('O id')
    .max(45, 'O id pode que ter no máximo 45 caracteres')
    .required('O id é obrigatório'),
  notification: yup.boolean().required('A flag de notificação é obrigatória'),
  description: yup.lazy((value) => {
    if (!value) {
      return yup.string().nullable(true).notRequired();
    }

    return string('A descrição').max(
      100,
      'A descrição pode que ter no máximo 100 caracteres'
    );
  }),
});
