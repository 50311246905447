import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { FaYoutube } from 'react-icons/fa';
import { BiSolidRightArrow, BiSolidLeftArrow } from 'react-icons/bi';
import {
  Button,
  Skeleton,
  Stack,
  ButtonGroup,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import {
  Container,
  Header,
  FiltersContainer,
  Body,
  Card,
  CardBody,
  Footer,
  Image,
  CardContent,
  Badge,
} from './styled';
import { getHour } from '../../../../utils';
import { listChannels } from '../../../../data/usecases/channels/list-all-channels';
import ModalComponent from './modal';
import ModalDelete from './delete';
import { deleteChannels } from '../../../../data/usecases/channels/delete-channels';
import Paragraph from '../../../components/paragraph';

const Channels: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const channels = useAppSelector((state) => state.channels);

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const [channelId, setChannelId] = useState<string | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const listAllChannels = (page: number) => {
    dispatch(listChannels({ page, limit: 1 }));
  };

  const onOpenModal = (id: string) => {
    setChannelId(id);
    onOpen();
  };

  useEffect(() => {
    listAllChannels(0);
  }, []);

  const closeDelete = () => {
    onCloseDelete();
    onClose();
    listAllChannels(0);
  };

  const removeChannels = () => {
    dispatch(
      deleteChannels(
        {
          id: channelId,
        },
        closeDelete
      )
    );
  };

  return (
    <Container>
      <Header>
        <Head title="Canais" icon={<FaYoutube />} />

        <FiltersContainer>
          <Button
            leftIcon={<MdAdd />}
            ringColor="blue.500"
            colorScheme="blue"
            variant="ghost"
            onClick={() => {
              history.push('/youtube/cadastro');
            }}
          >
            Adicionar novo canal
          </Button>
        </FiltersContainer>
      </Header>
      <Body>
        {channels.data ? (
          channels.data.map((value: any) => (
            <Card key={value.external_id}>
              <Image src={value.banner} alt={value.name} />

              <CardContent>
                <CardBody>
                  <Paragraph
                    align="left"
                    fontSize="default"
                    label="Nome do canal"
                  >
                    {value.name}
                  </Paragraph>
                  <Paragraph align="left" fontSize="default" label="Horários">
                    {value.description}
                  </Paragraph>
                  <Paragraph
                    align="left"
                    fontSize="default"
                    label="Notificação"
                  >
                    {value.notification ? 'Ativada' : 'Desativada'}
                  </Paragraph>

                  {value.status !== 'none' && (
                    <>
                      <Paragraph
                        align="left"
                        fontSize="default"
                        label="Status"
                      />
                      <Badge color={value.status === 'live' ? 'red' : 'gray'}>
                        {value.status === 'live' ? 'Ao vivo' : 'Em breve'}
                      </Badge>
                    </>
                  )}
                </CardBody>
                <CardBody />

                <Button
                  variant="solid"
                  colorScheme="blue"
                  onClick={() => onOpenModal(value.external_id)}
                >
                  Ações
                </Button>
              </CardContent>
            </Card>
          ))
        ) : (
          <Stack spacing="20px">
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton
                borderRadius="md"
                key={index}
                startColor="blue.500"
                endColor="blue.800"
                height="20px"
              />
            ))}
          </Stack>
        )}
      </Body>
      <Footer>
        <Paragraph align="left" fontSize="default" label="Items:">
          {channels?.pagination?.total_items || 0}
        </Paragraph>

        <ButtonGroup>
          <IconButton
            icon={<BiSolidLeftArrow />}
            colorScheme="blue"
            aria-label="Left"
            disabled={channels?.pagination?.current_page <= 0}
            onClick={() =>
              listAllChannels(channels.pagination.current_page - 1)
            }
          />

          <IconButton
            icon={<BiSolidRightArrow />}
            colorScheme="blue"
            aria-label="Right"
            disabled={
              channels?.pagination?.current_page >=
              channels?.pagination?.limit_page
            }
            onClick={() =>
              listAllChannels(channels.pagination.current_page + 1)
            }
          />
        </ButtonGroup>
      </Footer>

      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        onDelete={onOpenDelete}
        channelId={channelId}
      />

      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        removeChannels={removeChannels}
      />
    </Container>
  );
};

export default Channels;
