import { Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { RiInboxArchiveFill } from 'react-icons/ri';

import { toast } from 'react-toastify';
import { createImage } from '../../../../data/usecases/image/create-image';
import { storiesService } from '../../../../infra/http/web-service';
import { convertIntoBase64 } from '../../../../utils/file';
import Head from '../../../components/header';
import Input from '../../../components/input';
import Select from '../../../components/select';
import TextArea from '../../../components/text-area';

import {
  Box,
  Container,
  Divisor,
  File,
  Form,
  Header
} from './styled';
import { createStorySchema } from '../../../../validation/forms/stories/create-story';

const CreateStory: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);

  const getDefaultValueToSchedule = () => {
    const dateObj = new Date();

    // get the month in this format of 04, the same for months
    const month = (`0${dateObj.getMonth() + 1}`).slice(-2);
    const day = (`0${dateObj.getDate()}`).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    return shortDate;
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(createStorySchema),
  });

  const onFile = useCallback(
    async (file: React.ChangeEvent<HTMLInputElement>) => {
      const base64 = await convertIntoBase64(file.target.files);
      setValue('image', file.target.files?.[0]);
      setImage(base64 as string);
    },
    [image]
  );

  useEffect(() => {
    setValue('image', null);
  }, []);

  const onSubmit = async (value: any) => {
    try {
    setLoading(true);
    let url = null;

    if (value.type === 'text' && value.image) {
      toast.error('Não é possível adicionar imagem em um texto!');
      setLoading(false);
      return;
    }

    if (value.type === 'image' && !value.image) {
      toast.error('Imagem é obrigatória para o tipo "Stories"!');
      setLoading(false);
      return;
    }

    if (value.type === 'text' && !value.text) {
      toast.error('Texto é obrigatório para o tipo "Reflexão Diaria"!');
      setLoading(false);
      return;
    }

    if (value.link && !value.link_alias) {
      toast.error('Link de acesso precisa de um apelido!');
      setLoading(false);
      return;
    }

    if (!value.link && value.link_alias) {
      toast.error('Apelido do link precisa de um link de acesso!');
      setLoading(false);
      return;
    }

    if (value.image) {
      url = await createImage(value.image);
    }

    const story = {
      ...value,
      image: url,
    };

    await storiesService.create(story);

    toast.success('Stories criado com sucesso!');

    setLoading(false);
  } catch (error) {
    toast.error('Falha na criação dos stories!');
    setLoading(false);
  }
  };

  return (
    <Container>
      <Header>
        <Head title="Cadastro de Story" icon={<RiInboxArchiveFill />} />
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Divisor width="90%" scroll>
          <Box scroll={false}>
            <File>
              <label htmlFor="image">
                {image ? (
                  <img src={image} alt="Imagem para o stories" />
                ) : (
                  <FaCloudUploadAlt />
                )}

                <input
                  id="image"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  {...register('image')}
                  onChange={onFile}
                />
              </label>
            </File>
          </Box>

          <Box scroll>
            <TextArea
              label="Text"
              {...register('text')}
              error={errors.text}
            />

            <Input
              label="Link de acesso"
              type="text"
              placeholder="https://..."
              {...register('link')}
              error={errors.link}
            />

            <Input
              label="Apelido do Link"
              type="text"
              placeholder="Colocar nome do link para o stories"
              {...register('link_alias')}
              error={errors.link_alias}
            />

            <Input
              label="Agendamento"
              type="date"
              defaultValue={getDefaultValueToSchedule()}
              {...register('schedule_at')}
              error={errors.schedule_at}
            />

            <Select
              label="Tipo"
              {...register('type')}
              error={errors.type}
              items={[{
                name: 'Stories',
                externalId: 'image'
              }, {
                name: 'Reflexão Diaria',
                externalId: 'text'
              }]}
            />

          </Box>
        </Divisor>

        <Divisor width="10%" scroll={false}>
          <Box scroll={false}>
            <Button isLoading={loading} colorScheme="blue" type="submit">
              Cadastrar
            </Button>
          </Box>
        </Divisor>
      </Form>

    </Container>
  );
};

export default CreateStory;
