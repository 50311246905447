import React from 'react';
import { Switch } from 'react-router-dom';
import {
  Login,
  Home,
  Client,
  ResetPassword,
  Companies,
  Messages,
  Policy,
  RemoveAccount,
} from '../../presentation/pages';
import {
  LoginLayout,
  DashboardLayout,
  UserLayout,
} from '../../presentation/layouts';
import Routes from '../config/routes';
import ProductRoute from './product';
import StoryRoute from './story';
import ChannelsRoute from './channels';
import EventsRoute from './events';
import CalendarRoute from './calendar';
import NotificationRoute from './notification';
import TicketsRoute from './tickets';

const Router: React.FC = () => (
  <Switch>
    <Routes
      path="/"
      isPrivate={false}
      exact
      component={Login}
      layout={LoginLayout}
    />

    <Routes
      path="/policy"
      isPrivate={false}
      exact
      component={Policy}
      layout={UserLayout}
    />

    <Routes
      path="/reset-password"
      isPrivate={false}
      exact
      component={ResetPassword}
      layout={UserLayout}
    />

    <Routes
      path="/remover-conta"
      isPrivate={false}
      exact
      component={RemoveAccount}
      layout={UserLayout}
    />

    <Routes
      path="/dashboard"
      isPrivate
      exact
      component={Home}
      layout={DashboardLayout}
    />

    <Routes
      path="/empresa"
      isPrivate
      exact
      component={Companies}
      layout={DashboardLayout}
    />

    <Routes
      path="/clientes"
      isPrivate
      exact
      component={Client}
      layout={DashboardLayout}
    />

    <Routes
      path="/estoques"
      isPrivate
      component={ProductRoute}
      layout={DashboardLayout}
    />

    <Routes
      path="/agendas"
      isPrivate
      component={CalendarRoute}
      layout={DashboardLayout}
    />

    <Routes
      path="/eventos"
      isPrivate
      component={EventsRoute}
      layout={DashboardLayout}
    />

    <Routes
      path="/mensagens"
      isPrivate
      component={Messages}
      layout={DashboardLayout}
    />

    <Routes
      path="/notificacoes"
      isPrivate
      component={NotificationRoute}
      layout={DashboardLayout}
    />

    <Routes
      path="/youtube"
      isPrivate
      component={ChannelsRoute}
      layout={DashboardLayout}
    />

    <Routes
      path="/tickets"
      isPrivate
      component={TicketsRoute}
      layout={DashboardLayout}
    />

    <Routes
      path="/stories"
      isPrivate
      component={StoryRoute}
      layout={DashboardLayout}
    />
  </Switch>
);

export default Router;
