import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  FETCH_CALENDAR,
  DELETE_CALENDAR,
} from '../../../infra/redux/calendar/constants';
import { calendar } from '../../../infra/http/web-service';

export const deleteCalendar =
  (params: any, onClose: () => void) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CALENDAR });
    try {
      const { data } = await calendar.delete(params.id);

      dispatch({ type: DELETE_CALENDAR, payload: { id: params.id } });

      toast.success(data.message);
      onClose();
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_CALENDAR });
    }
  };
