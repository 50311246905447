import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  FETCH_EVENTS,
  UPDATE_EVENTS,
} from '../../../infra/redux/events/constants';
import { events } from '../../../infra/http/web-service';

export const updateEvents =
  (id: string, params: any, history: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_EVENTS });
    try {
      const { data } = await events.update(id, {
        ...params,
        video: params.video ? params.video : null,
        link: params.link ? params.link : null,
      });

      dispatch({ type: UPDATE_EVENTS, payload: data.payload });

      toast.success(data.message);
      history.go(-1);
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_EVENTS });
    }
  };
