import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  FETCH_CHANNELS,
  UPDATE_CHANNELS,
  REFRESH_CHANNELS,
} from '../../../infra/redux/channels/constants';
import { channels } from '../../../infra/http/web-service';

export const updateChannels =
  (id: string, params: any, history: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CHANNELS });
    try {
      const { data } = await channels.update(id, params);

      dispatch({ type: UPDATE_CHANNELS, payload: {} });
      toast.success(data.message);
      history.go(-1);
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_CHANNELS });
    }
  };

export const refreshChannels =
  (id: string, onClose: () => void) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CHANNELS });
    try {
      const { data } = await channels.refresh(id);

      dispatch({ type: REFRESH_CHANNELS, payload: {} });
      toast.success(data.message);
      onClose();
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_CHANNELS });
    }
  };
