import React, { useEffect, useState } from 'react';

import { Text } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Button from '../../../components/button';
import { SalesContainer, Div } from './styled';
import { graph } from '../../../../infra/http/web-service';

const Sales: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState([
    {
      quantity: 0,
      type: 'TESTIMONIALS',
    },
    {
      quantity: 0,
      type: 'DREAMS',
    },
    {
      quantity: 0,
      type: 'PRAYERS',
    },
  ]);

  const listMessages = async () => {
    try {
      const response = await graph.listMessage();

      if (!response.data?.payload?.data.length) {
        return;
      }

      setData(response.data.payload.data);
    } catch (error) {
      toast.error('Ops, não conseguimos buscar a quantidade de mensagens');
    }
  };

  useEffect(() => {
    listMessages();
  }, []);

  const getName = (alias: string) => {
    switch (alias) {
      case 'TESTIMONIALS':
        return 'Testemunhos';
      case 'DREAMS':
        return 'Sonhos';
      case 'PRAYERS':
        return 'Orações';
      default:
        return 'Sem informação';
    }
  };

  return (
    <SalesContainer>
      <div>
        <Text fontSize="md" fontWeight="light">
          Últimos 7 dias
        </Text>

        <Text fontSize="larger" fontWeight="bold">
          Mensagens recebidas
        </Text>
      </div>

      <div className="title">
        <Div>
          {data.map((value, index: number) => (
            <div key={index}>
              <div className="items">
                <Text>{getName(value.type)}</Text>
                <Text fontSize="sm" fontWeight="bold">
                  {value.quantity}
                </Text>
              </div>
              <hr />
            </div>
          ))}
        </Div>
      </div>

      <Button.Primary
        type="button"
        color="white"
        backgroundColor="blue"
        label="Visualizar mensagens"
        onClick={() => history.push('/mensagens')}
      />
    </SalesContainer>
  );
};

export default Sales;
