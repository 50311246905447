import React from 'react';

import { Text, Label, Div } from './styled';
import { Theme } from '../../../main/style/theme';

export type Params = {
  label?: string;
  align: 'center' | 'left' | 'right';
  fontSize: keyof Theme['styles']['font'];
};

const Paragraph: React.FC<Params> = ({ children, align, fontSize, label }) => (
  <Div>
    {label && (
      <Label fontSize={fontSize} align={align}>
        {label}
      </Label>
    )}

    <Text fontSize={fontSize} align={align}>
      {children}
    </Text>
  </Div>
);

export default Paragraph;
