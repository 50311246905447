import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FaHome,
  FaPowerOff,
  FaUsers,
  FaYoutube,
  FaCalendarCheck,
  FaTicketAlt,
  FaInstagram
} from 'react-icons/fa';
import { BsFillCalendarDateFill, BsFillSendFill } from 'react-icons/bs';
import { BiSolidMessageSquareDetail } from 'react-icons/bi';
import { ImBooks } from 'react-icons/im';
import { Avatar, Tooltip } from '@chakra-ui/react';
import NavItem from './item';
import menuImage from '../../../assets/icon.png';
import { NavContainer, Logo, NavList } from './styled';
import { useAppDispatch } from '../../../data/hooks/redux';
import { logout } from '../../../data/usecases/auth/logout';
import { SessionStorage } from '../../../utils/storage/session';
import { SESSION_AUTH } from '../../../main/config/constants';

type Params = {
  onClose: () => void;
};

const getIcon = (path: string) => {
  const factory = {
    '/dashboard': <FaHome />,
    '/clientes': <FaUsers />,
    '/notificacoes': <BsFillSendFill />,
    '/eventos': <FaCalendarCheck />,
    '/agendas': <BsFillCalendarDateFill />,
    '/estoques': <ImBooks />,
    '/stories': <FaInstagram />,
    '/mensagens': <BiSolidMessageSquareDetail />,
    '/youtube': <FaYoutube />,
    '/tickets': <FaTicketAlt />,
  } as { [params: string]: React.ReactElement };

  return factory[path];
};

const Navbar: React.FC<Params> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [menus, setMenus] = useState([]);

  const exit = () => {
    dispatch(logout(history));
  };

  const getMenus = () => {
    const session = SessionStorage.getItem(SESSION_AUTH) as any;

    setMenus(session.routes);
  };

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <NavContainer>
      <Logo>
        <Avatar
          // onClick={pushToCompany}
          size="xl"
          bgColor="white"
          name="Logo andando no profético"
          src={menuImage}
        />
      </Logo>
      <NavList>
        {menus.map((menu: any, index) => (
          <NavItem
            key={index}
            onClose={onClose}
            icon={getIcon(menu.path)}
            link={menu.path}
            label={menu.name}
          />
        ))}
      </NavList>
      <Tooltip label="Sair" bg="blue.500" placement="right" fontSize="md">
        <div
          className="exit"
          role="button"
          aria-hidden="true"
          onClick={exit}
          onKeyDown={exit}
        >
          <FaPowerOff />
        </div>
      </Tooltip>
    </NavContainer>
  );
};

export default Navbar;
