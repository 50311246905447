import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from '../../../data/hooks/redux';

import Input from '../../components/input';
import * as Button from '../../components/button';
import { Container, Form, Paragraph } from './styled';
import { resetPasswordSchema } from '../../../validation/forms';
import { ResetPassword } from '../../../domain/forms/reset-password';
import { resetPassword } from '../../../data/usecases/reset-password/reset-password';

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPassword>({
    resolver: yupResolver(resetPasswordSchema),
  });

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [showSecondPassword, setShowSecondPassword] = useState(true);

  const onSubmit = async (value: ResetPassword) => {
    setLoading(true);

    await resetPassword({
      password: value.password,
      token: history.location?.search?.replace('?token=', ''),
    });

    setLoading(false);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowSecondPassword = () => {
    setShowSecondPassword(!showSecondPassword);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>Alteração de senha</h2>

        <Paragraph>Crie uma nova senha para acessar o aplicativo.</Paragraph>

        <Input
          label="Nova senha"
          type={showPassword ? 'password' : 'text'}
          {...register('password')}
          error={errors.password}
          icon={
            showPassword ? (
              <IoMdEyeOff onClick={handleShowPassword} />
            ) : (
              <IoMdEye onClick={handleShowPassword} />
            )
          }
        />

        <Input
          label="Repita sua nova senha"
          type={showSecondPassword ? 'password' : 'text'}
          {...register('secondPassword')}
          error={errors.secondPassword}
          icon={
            showSecondPassword ? (
              <IoMdEyeOff onClick={handleShowSecondPassword} />
            ) : (
              <IoMdEye onClick={handleShowSecondPassword} />
            )
          }
        />

        <Button.Primary
          type="submit"
          label="Entrar"
          backgroundColor="blue"
          color="white"
          isLoading={loading}
          disable={loading}
        />
      </Form>
    </Container>
  );
};

export default Login;
