import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  LIST_ALL_TICKETS,
  FETCH_TICKET,
} from '../../../infra/redux/tickets/constants';
import { tickets } from '../../../infra/http/web-service';

export const listTickets = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_TICKET });
  try {
    const { data } = await tickets.listAll();

    dispatch({
      type: LIST_ALL_TICKETS,
      payload: { data: data.payload },
    });
  } catch (error: any) {
    requestError(error);
  } finally {
    dispatch({ type: FETCH_TICKET });
  }
};
