import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { BsFillCalendarDateFill } from 'react-icons/bs';
import { BiSolidRightArrow, BiSolidLeftArrow } from 'react-icons/bi';
import {
  Button,
  Skeleton,
  Stack,
  ButtonGroup,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import Paragraph from '../../../components/paragraph';
import {
  Container,
  Header,
  Body,
  Card,
  CardBody,
  CardFooter,
  Footer,
  Badge,
} from './styled';
import { calendarText, getColorMessage } from '../../../../utils';
import { listCalendar } from '../../../../data/usecases/calendar/list-all-calendar';
import { deleteCalendar } from '../../../../data/usecases/calendar/delete-calendar';
import ModalComponent from './modal';

const Calendar: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const calendar = useAppSelector((state) => state.calendar);

  const [calendarId, setCalendarId] = useState('');

  const onOpenModal = (id: string) => {
    setCalendarId(id);
    onOpen();
  };

  useEffect(() => {
    listAllCalendar(0);
  }, []);

  const listAllCalendar = (page: number) => {
    dispatch(listCalendar({ page, limit: 10 }));
  };

  const onCloseCalendar = () => {
    onClose();
    listAllCalendar(calendar?.pagination?.current_page || 0);
  };

  const removeCalendar = () => {
    dispatch(
      deleteCalendar(
        {
          id: calendarId,
        },
        onCloseCalendar
      )
    );
  };

  return (
    <Container>
      <Header>
        <Head title="Agenda" icon={<BsFillCalendarDateFill />} />
      </Header>
      <Body>
        {calendar.data ? (
          calendar.data.map((value: any) => (
            <Card key={value.external_id}>
              <Stack width="100%">
                <CardBody>
                  <Badge colorType={getColorMessage(value.frequency)}>
                    {calendarText(value.recurrence, value)}
                  </Badge>
                </CardBody>
                <Paragraph fontSize="default" label="Titulo" align="left">
                  {value.title}
                </Paragraph>
                <Paragraph fontSize="default" label="Descrição" align="left">
                  {value.description}
                </Paragraph>
                <Paragraph fontSize="default" label="Local" align="left">
                  {value.local}
                </Paragraph>

                <CardFooter>
                  <Button
                    variant="solid"
                    colorScheme="red"
                    onClick={() => onOpenModal(value.external_id)}
                  >
                    Deletar
                  </Button>
                </CardFooter>
              </Stack>
            </Card>
          ))
        ) : (
          <Stack spacing="20px">
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton
                borderRadius="md"
                key={index}
                startColor="blue.500"
                endColor="blue.800"
                height="20px"
              />
            ))}
          </Stack>
        )}
      </Body>
      <Footer>
        <Button
          leftIcon={<MdAdd />}
          ringColor="blue.500"
          colorScheme="blue"
          variant="ghost"
          onClick={() => {
            history.push('/agendas/cadastro');
          }}
        >
          Adicionar
        </Button>
        <ButtonGroup>
          {calendar?.pagination?.current_page > 0 && (
            <IconButton
              icon={<BiSolidLeftArrow />}
              colorScheme="blue"
              aria-label="Left"
              onClick={() =>
                listAllCalendar(calendar.pagination.current_page - 1)
              }
            />
          )}

          {calendar?.pagination?.current_page <
            calendar?.pagination?.limit_page && (
            <IconButton
              icon={<BiSolidRightArrow />}
              colorScheme="blue"
              aria-label="Right"
              onClick={() =>
                listAllCalendar(calendar.pagination.current_page + 1)
              }
            />
          )}
        </ButtonGroup>
      </Footer>
      <ModalComponent
        removeCalendar={removeCalendar}
        isOpen={isOpen}
        isLoading={calendar.isFetch}
        onClose={onClose}
      />
    </Container>
  );
};

export default Calendar;
