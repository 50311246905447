import React, { memo } from 'react';
import { TextAreaDTO } from '../../../domain/components/text-area-dto';

import { Container, Error, TextArea, Label } from './styled';

const TextAreaComponent = React.forwardRef<HTMLTextAreaElement, TextAreaDTO>(
  (props, ref) => {
    const { name, label, icon, error, ...rest } = props;

    return (
      <Container>
        <Label htmlFor={name}>{label}</Label>

        <TextArea name={name} error={!!error} ref={ref} {...rest} />

        {icon}

        {error && <Error>{error.message}</Error>}
      </Container>
    );
  }
);

export default memo(TextAreaComponent);
