import React from 'react';

import { Container, Title } from './styled';
import Paragraph from '../../components/paragraph';

const Policy: React.FC = () => (
  <Container>
    <h2>TERMO DE PRIVACIDADE</h2>
    <h2>APLICATIVO ANDANDO NO PROFÉTICO</h2>

    <Title>
      1. <b>Introdução</b>
    </Title>

    <Paragraph align="left" fontSize="default">
      A privacidade dos usuários do nosso aplicativo é muito importante para
      nós, e estamos comprometidos em protegê-la.
    </Paragraph>

    <Paragraph align="left" fontSize="default">
      Esta política explica o que faremos com suas informações pessoais.
    </Paragraph>

    <Title>
      2. <b>Coleta de Dados Pessoais</b>
    </Title>

    <Paragraph align="left" fontSize="default">
      Os seguintes tipos de informações pessoais podem ser coletados,
      armazenados e usados:
    </Paragraph>

    <ul>
      <li>
        Informações como seu endereço de e-mail, que você digita quando se
        registra em nosso aplicativo;
      </li>
      <li>
        Informações que você digita ao criar um perfil em nosso aplicativo – por
        exemplo, seu nome, fotos de perfil e data de nascimento.
      </li>
      <li>
        Informações que você publica em nosso aplicativo, incluindo orações,
        testemunhos ou sonhos.
      </li>
    </ul>

    <Paragraph align="left" fontSize="default">
      Antes de nos divulgar dados pessoais de outra pessoa, você deve obter o
      consentimento dessa pessoa para a divulgação e o processamento dessas
      informações pessoais de acordo com esta política. Deve, também, informar a
      finalidade da coleta e nos enviar o consentimento do terceiro nestes
      moldes.
    </Paragraph>

    <Title>
      3. <b>Uso de suas informações pessoais</b>
    </Title>

    <Paragraph align="left" fontSize="default">
      As informações pessoais que nos são enviadas por meio de nosso aplicativo
      serão usadas para os fins especificados nesta Política.
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      Podemos usar suas informações pessoais para o seguinte:
    </Paragraph>

    <ul>
      <li>
        Administrar nosso aplicativo e nossos negócios visando a uma adequada
        prestação de serviços;
      </li>
      <li>
        Personalizar nosso aplicativo para você, tornando a navegação mais
        amistosa;
      </li>
      <li>Enviar comunicações comerciais que não sejam de marketing;</li>
      <li>Manter nosso aplicativo seguro e evitar fraudes.</li>
    </ul>
    <Paragraph align="left" fontSize="default">
      Sem seu consentimento expresso, não forneceremos suas informações pessoais
      a terceiros para fins de marketing direto por parte deles ou de terceiros.
    </Paragraph>

    <Title>
      4. <b> Divulgação de Dados Pessoais</b>
    </Title>

    <Paragraph align="left" fontSize="default">
      Podemos divulgar suas informações pessoais a qualquer um de nossos
      funcionários, executivos, seguradoras, consultores profissionais, agentes,
      fornecedores ou subcontratados conforme razoavelmente necessário para os
      fins estabelecidos nesta política.
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      Podemos divulgar suas informações pessoais a qualquer membro de nosso
      grupo de empresas (isso significa nossas subsidiárias, nossa holding e
      todas as suas subsidiárias) conforme razoavelmente necessário para os fins
      estabelecidos nesta política.
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      Podemos divulgar suas informações pessoais:
    </Paragraph>

    <ul>
      <li>Na medida em que somos obrigados a fazê-lo por lei;</li>
      <li>
        Em relação a qualquer processo judicial em andamento ou potencial, desde
        que haja ordem específica e fundamentada nesse sentido;
      </li>
      <li>
        Para estabelecer, exercer ou defender nossos direitos legais (incluindo
        fornecer informações a terceiros para fins de prevenção de fraudes e
        redução do risco de crédito), ocasião em que você será imediatamente
        informado;
      </li>
      <li>
        Ao comprador (ou comprador em potencial) de qualquer negócio ou ativo
        que estejamos vendendo (ou contemplando vender); e
      </li>
      <li>
        A qualquer pessoa que acreditemos razoavelmente que possa solicitar a um
        tribunal ou outra autoridade competente a divulgação dessas informações
        pessoais, quando, em nossa opinião razoável, for provável que tal
        tribunal ou autoridade ordene a divulgação dessas informações pessoais.
      </li>
    </ul>
    <Paragraph align="left" fontSize="default">
      Nunca forneceremos suas informações pessoais a terceiros a não ser
      mediante seu consentimento ou nos casos estabelecidos nesta Política de
      Privacidade.
    </Paragraph>

    <Title>
      5. <b> Transferências Internacionais de Dados Pessoais</b>
    </Title>

    <Paragraph align="left" fontSize="default">
      As informações que coletamos podem ser armazenadas, processadas e
      transferidas entre qualquer um dos países em que operamos, a fim de nos
      permitir usar as informações de acordo com esta Política de Privacidade,
      nos termos do artigo 33 da Lei nº 13.709/2018, a Lei Geral de Proteção de
      Dados, ou LGPD.
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      As informações que coletamos podem ser transferidas para os seguintes
      países que não possuem leis de proteção de dados equivalentes às vigentes
      no Espaço Econômico Europeu: Estados Unidos da América, Rússia, Japão,
      China e Índia.
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      As informações pessoais que você publica em nosso aplicativo ou envia para
      publicação em nosso aplicativo podem estar disponíveis, através da
      internet, em todo o mundo. Não podemos impedir o uso ou uso indevido de
      tais informações por terceiros.
    </Paragraph>

    <Title>
      6. <b> Retenção de Dados Pessoais</b>
    </Title>

    <Paragraph align="left" fontSize="default">
      Esta Seção define nossas políticas e procedimentos de retenção e
      tratamento de dados pessoais, projetados para ajudar a garantir o
      cumprimento de nossas obrigações legais em relação ao tratamento e
      exclusão de dados pessoais.
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      As informações pessoais que processamos para qualquer propósito ou
      propósitos não devem ser mantidas por mais tempo do que o necessário para
      esse propósito ou propósitos.
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      Não obstante as outras disposições desta Seção reteremos documentos
      (incluindo documentos eletrônicos) que contenham dados pessoais:
    </Paragraph>
    <ul>
      <li>Na medida em que somos obrigados a fazê-lo por lei;</li>
      <li>
        Se acreditarmos que os documentos podem ser relevantes para qualquer
        processo judicial em andamento ou potencial.
      </li>
    </ul>

    <Title>
      7. <b>Segurança de seus dados pessoais</b>
    </Title>

    <Paragraph align="left" fontSize="default">
      Tomaremos as devidas precauções técnicas e organizacionais para evitar a
      perda, mau uso ou alteração de seus dados pessoais.
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      Armazenaremos todas as suas informações pessoais fornecidas em nossos
      servidores seguros (protegidos por senha e firewall).
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      Sempre investiremos nas mais adequadas ferramentas de cybersegurança para
      assegurar a incolumidade de seus dados pessoais.
    </Paragraph>
    <Paragraph align="left" fontSize="default">
      Quaisquer incidentes de segurança de dados que envolver seus dados serão
      prontamente informados a você. Tomaremos imediatas providências para
      excluir ou mitigar os respectivos efeitos.
    </Paragraph>

    <Title>
      8. <b>Alterações da Política de Privacidade</b>
    </Title>

    <Paragraph align="left" fontSize="default">
      Podemos atualizar esta Política de Privacidade periodicamente, através da
      publicação de uma nova versão em nosso aplicativo/site.
    </Paragraph>

    <Paragraph align="left" fontSize="default">
      Você deve verificar esta página ocasionalmente para garantir que
      compreende quaisquer alterações nesta política. Podemos notificá-lo sobre
      alterações nesta política por e-mail ou através do sistema de mensagens
      privadas em nosso aplicativo.
    </Paragraph>

    <Paragraph align="left" fontSize="default">
      Em caso de dúvidas sobre esta Política de Privacidade você sempre poderá
      nos contatar por meio do site.
    </Paragraph>

    <Title>
      9. <b>Seus Direitos</b>
    </Title>

    <Paragraph align="left" fontSize="default">
      Você pode nos instruir a fornecer qualquer informação pessoal que
      detenhamos sobre você. Pode, também, na forma da lei, pedir verificação,
      alteração, correção, portabilidade e exclusão de seus dados pessoais.
    </Paragraph>

    <Paragraph align="left" fontSize="default">
      Tratamos suas informações pessoais solicitadas na extensão permitida por
      lei, especialmente as leis de proteção de dados.
    </Paragraph>

    <Paragraph align="left" fontSize="default">
      Seus dados pessoais poderão a qualquer momento ser solicitados por você.
      Os direitos do titular, previstos nos artigos 17 a 20 da LGPD, poderão ser
      exercidos a qualquer tempo e sua concretização será por nós providenciada
      com a maior brevidade possível.
    </Paragraph>
  </Container>
);

export default Policy;
