import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  CreateChannels,
  Channels,
  UpdateChannels,
} from '../../presentation/pages';

const ChannelsRoute: React.FC<any> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/`} exact component={Channels} />

    <Route path={`${match.url}/cadastro`} exact component={CreateChannels} />

    <Route
      path={`${match.url}/atualizar/:id`}
      exact
      component={UpdateChannels}
    />
  </Switch>
);

export default ChannelsRoute;
