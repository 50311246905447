import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { eachDayOfInterval, format, sub } from 'date-fns';
import { props } from '../../../../main/style/theme';
import { graph } from '../../../../infra/http/web-service';

const Graphic: React.FC = () => {
  const { styles } = props;

  const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0]);

  const matrizDate = eachDayOfInterval({
    start: sub(new Date(), {
      days: 7,
    }),
    end: new Date(),
  });

  const options: { series: any; table: ApexOptions } = {
    table: {
      chart: {
        type: 'bar',
        toolbar: {
          autoSelected: 'pan',
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        categories: matrizDate.map((value) => format(value, 'dd/MM')),
      },
      colors: [styles.colors.blue.light, styles.colors.blue.bold],
    },
    series: [
      {
        name: 'Usuário',
        data,
      },
    ],
  };

  const listHome = async () => {
    try {
      const response = await graph.listUserMetric();

      setData(response.data.payload.data);
    } catch (error) {
      toast.error('Ops, não conseguimos buscar a quantidade de mensagens');
    }
  };

  useEffect(() => {
    listHome();
  }, []);

  return (
    <>
      <Stat>
        <StatLabel fontWeight="light">Últimos 7 dias</StatLabel>
        <StatNumber>Usuários</StatNumber>
      </Stat>

      <Chart
        type="bar"
        width="100%"
        height="80%"
        options={options.table}
        series={options.series}
      />
    </>
  );
};

export default Graphic;
