import { ActionsType } from '../../../domain/redux/action';
import { ProductsTypeReducer } from '../../../domain/redux/reducers';
import {
  LIST_ALL_PRODUCT_TYPE,
  CLEAN_PRODUCT_TYPE,
  CREATE_PRODUCT_TYPE,
  FETCH_PRODUCT_TYPE,
} from './constants';

const INITIAL_STATE = {
  isFetch: false,
} as ProductsTypeReducer;

const productTypeReducer = (state = INITIAL_STATE, action: ActionsType) => {
  switch (action.type) {
    case LIST_ALL_PRODUCT_TYPE:
      return {
        data: action.payload.map((value: any) => ({
          externalId: value.external_id,
          name: value.type,
        })),
        isFetch: state.isFetch,
      };
    case CREATE_PRODUCT_TYPE:
      return {
        data: [...state.data, action.payload],
      };
    case CLEAN_PRODUCT_TYPE:
      return INITIAL_STATE;
    case FETCH_PRODUCT_TYPE:
      return {
        ...state,
        isFetch: !state.isFetch,
      };
    default:
      return state;
  }
};

export { productTypeReducer };
