import { ActionsType } from '../../../domain/redux/action';
import { CalendarReducer } from '../../../domain/redux/reducers';
import {
  LIST_ALL_CALENDAR,
  CLEAN_CALENDAR,
  CREATE_CALENDAR,
  DELETE_CALENDAR,
  FETCH_CALENDAR,
} from './constants';

const INITIAL_STATE = {
  isFetch: false,
} as CalendarReducer;

const calendarReducer = (state = INITIAL_STATE, action: ActionsType) => {
  switch (action.type) {
    case LIST_ALL_CALENDAR:
      return {
        ...action.payload,
        isFetch: state.isFetch,
      };
      case DELETE_CALENDAR:
        return {
          isFetch: state.isFetch,
        };
    case CREATE_CALENDAR:
      return state;
    case CLEAN_CALENDAR:
      return INITIAL_STATE;
    case FETCH_CALENDAR:
      return {
        ...state,
        isFetch: !state.isFetch,
      };
    default:
      return state;
  }
};

export { calendarReducer };
