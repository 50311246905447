import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  FETCH_NOTIFICATION,
  DELETE_NOTIFICATION,
} from '../../../infra/redux/notifications/constants';
import { notifications } from '../../../infra/http/web-service';

export const deleteNotification =
  (params: any, onClose: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_NOTIFICATION });
    try {
      const { data } = await notifications.delete(params.id);

      dispatch({ type: DELETE_NOTIFICATION, payload: {} });

      toast.success(data.message);
      onClose();
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_NOTIFICATION });
    }
  };
