import { ActionsType } from '../../../domain/redux/action';
import { MessagesReducer } from '../../../domain/redux/reducers';
import {
  LIST_ALL_MESSAGES,
  CLEAN_MESSAGE,
  FETCH_MESSAGE,
  LIST_MESSAGE_BY_ID,
  DELETE_MESSAGE,
} from './constants';

const INITIAL_STATE = {
  isFetch: false,
} as MessagesReducer;

const messagesReducer = (state = INITIAL_STATE, action: ActionsType) => {
  switch (action.type) {
    case LIST_ALL_MESSAGES:
      return {
        ...state,
        ...action.payload,
        isFetch: state.isFetch,
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        data: [
          ...state.data.filter(
            (value: any) => value?.message?.external_id !== action.payload?.id
          ),
        ],
        isFetch: state.isFetch,
      };
    case LIST_MESSAGE_BY_ID:
      return {
        ...state,
        item: action.payload,
        isFetch: state.isFetch,
      };
    case CLEAN_MESSAGE:
      return INITIAL_STATE;
    case FETCH_MESSAGE:
      return {
        ...state,
        isFetch: !state.isFetch,
      };
    default:
      return state;
  }
};

export { messagesReducer };
