import { cnpj, cpf } from 'cpf-cnpj-validator';
import * as yup from 'yup';
import { format, subDays } from 'date-fns';

export const string = (field: string) =>
  yup
    .string()
    .nullable(true)
    .min(3, `${field} tem que ter no mínimo 3 caracteres`);

export const uuid = (field: string) =>
  yup.string().uuid(`${field} está fora dos padrões`);

export const number = (field: string) =>
  yup
    .string()
    .nullable(true)
    .matches(/[0-9]/, `${field} precisa ser um número`);

export const stringNullable = yup.string().notRequired().nullable(true);

export const url = yup
  .string()
  .matches(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:\\/?#[\]@%!\\$&'\\(\\)\\*\\+,;=.]+$/,
    'A url está inválida'
  )
  .nullable(true);

export const phone = yup
  .string()
  .matches(/^(\d{7,20})$/gi, 'O telefone está fora dos padrões')
  .required('O telefone é obrigatório');

export const document = yup
  .mixed()
  .nullable(true)
  .test('is-cpf-or-cnpj', 'O CPF/CNPJ informado não é válido', (value) => {
    if (value?.length <= '11') {
      return cpf.isValid(value);
    }

    return cnpj.isValid(value);
  });

export const email = yup
  .string()
  .trim()
  .matches(
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    'Valor inserido não corresponde a um e-mail'
  )
  .nullable(true)
  .max(80, 'O e-mail pode que ter no máximo 80 caracteres')
  .required('O e-mail é obrigatório');

export const password = yup
  .string()
  .trim()
  .min(6, 'A senha está fora dos padrões')
  .matches(
    /^([a-z]|[A-Z]|[0-9]|[@#$%^&+=,])+$/gi,
    'A senha está fora dos padrões'
  )
  .required('A senha é obrigatória');

export const dateOfBirth = yup
  .date()
  .max(
    format(subDays(new Date(), 10), 'yyyy-MM-dd'),
    `A data de nascimento não pode ser maior que ${format(
      subDays(new Date(), 10),
      'dd/MM/yyyy'
    )}`
  )
  .required('A data de nascimento é obrigatória');

export const messageType = yup
  .string()
  .matches(
    /^DREAMS$|^PRAYERS$|^TESTIMONIALS$/,
    'O tipo da mensagem precisa ser "sonhos", "orações" ou "testemunhos"'
  )
  .required('O tipo da mensagem é obrigatório');

export const model = yup
  .string()
  .matches(/^products$|^events$/, 'O modelo precisa ser "produto" ou "evento"');

// export const nameSocialMedias = yup
//   .mixed()
//   .nullable(true)
//   .test('is-social-medias', 'A rede social informada não é válida', (value) =>
//     socialsMediasAllowed.includes(value)
//   );

export const onlyDate = yup
  .string()
  .trim()
  .matches(
    /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/gi,
    'A data está fora dos padrões'
  );

export const onlyHour = yup
  .string()
  .trim()
  .matches(/^([0-9]{2}):([0-9]{2})$/gi, 'A hora está fora dos padrões');

export const frequency = yup
  .string()
  .matches(
    /^day$|^week$|^month$/,
    'A frequência da agenda precisa ser "diário", "semanal" ou "mensal"'
  );

export const lives = yup.object().shape({
  hour: onlyHour,
});

export const secondPassword = yup
  .string()
  .trim()
  .test(
    'is-same-than',
    'A senha está diferente da digitada acima',
    function second(value) {
      return value === this.parent.password;
    }
  )
  .required('A confirmação de senha é obrigatória');
