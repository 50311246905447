import styled from 'styled-components';

export const Container = styled.div`
  width: 60px;
  height: 7vh;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.styles.spacing.base};

  background-color: ${({ theme }) => theme.styles.colors.white.opacity};

  color: ${({ theme }) => theme.styles.colors.gray.bold};

  svg {
    font-size: ${({ theme }) => theme.styles.font.medium};
  }

  :hover {
    color: ${({ theme }) => theme.styles.colors.white.normal};
    background-color: ${({ theme }) => theme.styles.colors.blue.medium};
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 50px;
    height: 5vh;

    border-radius: ${({ theme }) => theme.styles.spacing.short};

    svg {
      font-size: ${({ theme }) => theme.styles.font.default};
    }
  }
`;
