export const getStringValue = (type: string) => {
  const object: any = {
    PRAYERS: 'Oração',
    TESTIMONIALS: 'Testemunho',
    DREAMS: 'Sonho',
  };

  return object[type] || 'Sem informação';
};

export const getColorMessage = (type: string) => {
  const object: any = {
    PRAYERS: 'purple',
    TESTIMONIALS: 'orange',
    DREAMS: 'pink',
  };

  return object[type] || 'blue';
};
