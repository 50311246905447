import {
  Button,
  Skeleton,
  Stack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { ImBooks } from 'react-icons/im';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { storiesService } from '../../../infra/http/web-service';
import Head from '../../components/header';
import {
  Body,
  Card,
  CardBody,
  CardFooter,
  Container,
  FiltersContainer,
  Header,
  Image,
  LinkText,
  Paragraph,
  TextContent
} from './styled';

const Stories: React.FC = () => {
  const history = useHistory();

  const [stories, setStories] = useState([]);

  const listAllStories = async () => {
     const { data } = await storiesService.listAll();
     setStories(data.payload);
  };

  const formatScheduleDate = (date: string) => {
    const newDate = new Date(date);
    const formattedDate = `${newDate.getDate()}/${newDate.getMonth() + 1}`;
    return formattedDate;
  };

  const deleteStory = async (id: number) => {
    await storiesService.delete(id);
    listAllStories();
  };

  useEffect(() => {
    listAllStories();
  }, []);

  return (
    <Container>
      <Header>
        <Head title="Meus Stories" icon={<ImBooks />} />
        <FiltersContainer>
          <Button
            leftIcon={<MdAdd />}
            ringColor="blue.500"
            colorScheme="blue"
            variant="ghost"
            onClick={() => {
              history.push('/stories/cadastro');
            }}
          >
            Adicionar
          </Button>
        </FiltersContainer>
      </Header>
      <Body>
        {stories ? (
          stories.map((story: any) => (
            <Card key={story.story_id}>
              <Stack width="100%">
                <CardBody>
                  {story.schedule_at && (
                    <Paragraph>Agendado para: {formatScheduleDate(story.schedule_at)}</Paragraph>
                  )}
                  {story?.image && <Image src={story.image} alt={story.story_id} />}
                  {story?.link && <LinkText href={story.link}>Link: {story.link_alias}</LinkText>}
                  {story?.text && <TextContent>{story.text}</TextContent>}
                </CardBody>
                <CardFooter>
                  <Button
                    variant="solid"
                    colorScheme="red"
                    onClick={() => { deleteStory(story.story_id); }}
                  >
                    Deletar
                  </Button>
                </CardFooter>
              </Stack>
            </Card>
          ))
        ) : (
          <Stack spacing="20px">
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton
                borderRadius="md"
                key={index}
                startColor="blue.500"
                endColor="blue.800"
                height="20px"
              />
            ))}
          </Stack>
        )}
      </Body>
    </Container>
  );
};

export default Stories;
