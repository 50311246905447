import React from 'react';

import Graphic from './graphic';
import Banner from './banner';
import Sales from './sales';
import Details from './details';
import Notification from './notification';
import Channels from './channels';
import Products from './products';
import Events from './events';
import Forms from './forms';

import { Container, GraphicContainer, Divisor } from './styled';

const Home: React.FC = () => (
  <Container>
    <Banner />
    <Details />

    <Divisor>
      <GraphicContainer>
        <Graphic />
      </GraphicContainer>

      <Sales />
    </Divisor>

    <h2>Formulários</h2>

    <Forms />

    <h2>Canais e notificações</h2>
    <Divisor>
      <Notification />

      <Channels />
    </Divisor>

    <h2>Eventos e livros</h2>
    <Divisor>
      <Products />

      <Events />
    </Divisor>
  </Container>
);

export default Home;
