import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Paragraph from '../../../components/paragraph';

import { Container, AutoLayout } from './styled';
import { formatDate } from '../../../../utils';
import { clients } from '../../../../infra/http/web-service';
import { getNotificationCapability } from '../../../../utils/device';

export type ModelDto = {
  isOpen: boolean;
  onClose: () => void;
  userId: string | null;
};

const DrawerContainer: React.FC<ModelDto> = ({ isOpen, onClose, userId }) => {
  const [user, setUser] = useState<any>(null);
  const [device, setDevice] = useState<any>(null);

  const listUserById = async (id: string) => {
    try {
      const { data } = await clients.listById(id);

      setUser(data.payload);
      console.log('USER', data.payload);
    } catch (error) {
      setUser(null);
    }
  };

  const listDeviceById = async (id: string) => {
    try {
      const { data } = await clients.listDeviceByUserId(id);

      setDevice(data.payload);
      console.log('DEVICE', data.payload);
    } catch (error) {
      setDevice(null);
    }
  };

  useEffect(() => {
    if (!userId) return;

    listUserById(userId);
    listDeviceById(userId);
  }, [userId]);

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Informações</DrawerHeader>

          <DrawerBody>
            <AutoLayout>
              <h2>Usuário</h2>

              {user && (
                <>
                  <Paragraph
                    align="left"
                    fontSize="small"
                    label="Identificador"
                  >
                    {user?.external_id}
                  </Paragraph>

                  <Paragraph align="left" fontSize="small" label="Nome">
                    {user?.name}
                  </Paragraph>

                  <Paragraph align="left" fontSize="small" label="E-mail">
                    {user?.email}
                  </Paragraph>

                  {user?.date_of_birth && (
                    <Paragraph
                      align="left"
                      fontSize="small"
                      label="Data de nascimento"
                    >
                      {format(new Date(user.date_of_birth), 'dd/MM/yyyy')}
                    </Paragraph>
                  )}

                  <Paragraph align="left" fontSize="small" label="Criado em">
                    {formatDate(user?.created_at)}
                  </Paragraph>
                </>
              )}
            </AutoLayout>

            <AutoLayout>
              <h2>Device</h2>

              {device?.model ? (
                <>
                  <Paragraph align="left" fontSize="small" label="Modelo">
                    {device?.model}
                  </Paragraph>

                  {device?.updated_at && (
                    <Paragraph
                      align="left"
                      fontSize="small"
                      label="Atualizado em"
                    >
                      {formatDate(device.updated_at)}
                    </Paragraph>
                  )}

                  <Paragraph align="left" fontSize="small" label="Plataforma">
                    {device?.settings?.brand}
                  </Paragraph>

                  <Paragraph align="left" fontSize="small" label="Sistema">
                    {device?.settings?.os_name}
                  </Paragraph>

                  <Paragraph align="left" fontSize="small" label="Notificação">
                    {getNotificationCapability(device?.capabilities)}
                  </Paragraph>
                </>
              ) : (
                <p>Não possui cadastrado</p>
              )}
            </AutoLayout>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button colorScheme="red" onClick={onClose}>
              Fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DrawerContainer;
