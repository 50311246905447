import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { NavItemDTO } from '../../../../domain/components/nav-item-dto';

import { Container } from './styled';

const item: React.FC<NavItemDTO> = ({ icon, label, link, onClose }) => (
  <Link to={link} onClick={onClose}>
    <Tooltip label={label} bg="blue.500" placement="right" fontSize="md">
      <Container>{icon}</Container>
    </Tooltip>
  </Link>
);

export default item;
