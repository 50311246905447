import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaArchive } from 'react-icons/fa';

import { useAppDispatch, useAppSelector } from '../../../data/hooks/redux';
import Head from '../../components/header';
import { Container, Header } from './styled';
import { listProducts } from '../../../data/usecases/products/list-all-product';

const Products: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.product);

  useEffect(() => {
    listAllProducts();
  }, []);

  const listAllProducts = () => {
    dispatch(listProducts({ page: 0, limit: 10, status: 'active' }));
  };

  return (
    <Container>
      <Header>
        <Head title="Meus produtos" icon={<FaArchive />} />
      </Header>
    </Container>
  );
};

export default Products;
