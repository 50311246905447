import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  LIST_ALL_NOTIFICATIONS,
  FETCH_NOTIFICATION,
} from '../../../infra/redux/notifications/constants';
import { notifications } from '../../../infra/http/web-service';

export const listNotifications =
  (params: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_NOTIFICATION });
    try {
      const { data } = await notifications.listAll(params);

      dispatch({
        type: LIST_ALL_NOTIFICATIONS,
        payload: data.payload,
      });
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_NOTIFICATION });
    }
  };
