import { ActionsType } from '../../../domain/redux/action';
import { NotificationsReducer } from '../../../domain/redux/reducers';
import {
  LIST_ALL_NOTIFICATIONS,
  CLEAN_NOTIFICATION,
  CREATE_NOTIFICATION,
  FETCH_NOTIFICATION,
  DELETE_NOTIFICATION,
} from './constants';

const INITIAL_STATE = {
  isFetch: false,
} as NotificationsReducer;

const notificationsReducer = (state = INITIAL_STATE, action: ActionsType) => {
  switch (action.type) {
    case LIST_ALL_NOTIFICATIONS:
      return {
        ...action.payload,
        isFetch: state.isFetch,
      };
    case DELETE_NOTIFICATION:
      return {
        ...action.payload,
        isFetch: state.isFetch,
      };
    case CREATE_NOTIFICATION:
      return {
        isFetch: state.isFetch,
      };
    case CLEAN_NOTIFICATION:
      return INITIAL_STATE;
    case FETCH_NOTIFICATION:
      return {
        ...state,
        isFetch: !state.isFetch,
      };
    default:
      return state;
  }
};

export { notificationsReducer };
