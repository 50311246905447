import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 10%;
`;

export const Form = styled.form`
  width: 100%;
  height: 90%;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: 16px;
`;

export const Divisor = styled.div<{ width: string; scroll: boolean }>`
  width: 100%;
  height: ${({ width }) => width};

  padding: 20px;
  gap: 20px;

  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'unset')};
    padding: 10px;
  }
`;

export const Box = styled.div<{ scroll: boolean }>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'unset')};

  @media ${({ theme }) => theme.devices.mobileL} {
    height: 100%;
    overflow-y: unset;
  }
`;

export const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const File = styled.div`
  width: 100%;
  height: calc(100% - 40px);

  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.styles.spacing.base};
  border: 1px dashed ${({ theme }) => theme.styles.colors.gray.medium};
  overflow: hidden;
  cursor: pointer;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  svg {
    width: 50%;
    height: 50%;
    color: ${({ theme }) => theme.styles.colors.gray.medium};
  }

  img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  input {
    display: none;
  }
`;

export const Paragraph = styled.p`
  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: lighter;

  font-size: ${({ theme }) => theme.styles.font.default};
`;

export const BoxFile = styled.div`
  width: 100%;
  height: 200px;

  p {
    line-height: 1.5em;
    letter-spacing: 0.5px;
    text-align: left;
    font-size: ${({ theme }) => theme.styles.font.default};
  }
`;
