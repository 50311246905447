import React from 'react';

import loginImage from '../../../assets/Logo_profeta.png';

import { Container, LeftContainer, RightContainer } from './styled';

const UserLayout: React.FC = ({ children }) => (
  <Container>
    <LeftContainer>
      <img src={loginImage} alt="Home dashboard" />
    </LeftContainer>
    <RightContainer>{children}</RightContainer>
  </Container>
);

export default UserLayout;
