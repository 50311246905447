import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  FETCH_CHANNELS,
  CREATE_CHANNELS,
} from '../../../infra/redux/channels/constants';
import { channels } from '../../../infra/http/web-service';

export const createChannels =
  (params: any, history: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CHANNELS });
    try {
      const { data } = await channels.create(params);

      dispatch({ type: CREATE_CHANNELS, payload: data.payload });

      toast.success(data.message);
      history.go(-1);
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_CHANNELS });
    }
  };
