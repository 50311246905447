import React, { useState } from 'react';
import { FaYoutube } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import Input from '../../../components/input';
import TextArea from '../../../components/text-area';
import { Container, Header, Form, Divisor, Box } from './styled';
import { createChannelSchema } from '../../../../validation/forms/channels/create-channels';
import { createChannels } from '../../../../data/usecases/channels/create-channels';
import Switch from '../../../components/switch';

const CreateChannels: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(createChannelSchema),
  });

  const onSubmit = async (value: any) => {
    setLoading(true);

    dispatch(
      createChannels(
        {
          youtubeId: value.youtubeId,
          notification: value.notification,
          description: value.description || null,
          lives: [],
        },
        history
      )
    );
    setLoading(false);
  };

  return (
    <Container>
      <Header>
        <Head title="Cadastro de canal" icon={<FaYoutube />} />
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Divisor width="90%" scroll>
          <Box scroll={false}>
            <Input
              label="Id do canal no youtube"
              type="text"
              {...register('youtubeId')}
              error={errors.youtubeId}
            />

            <Switch
              label="Deseja que envie notificação?"
              {...register('notification')}
              error={errors.notification}
              enabled
            />

            <TextArea
              label="Escreva a descrição do canal"
              placeholder="Lives todos os dias às 06h30, 19h00 e 22h00"
              {...register('description')}
              error={errors.description}
            />
          </Box>
        </Divisor>

        <Divisor width="10%" scroll={false}>
          <Box scroll={false}>
            <Button isLoading={loading} colorScheme="blue" type="submit">
              Cadastrar
            </Button>
          </Box>
        </Divisor>
      </Form>
    </Container>
  );
};

export default CreateChannels;
