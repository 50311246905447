import { cpf as validCpf, cnpj as validCnpj } from 'cpf-cnpj-validator';
import { format, sub } from 'date-fns';
import { TOKEN } from '../main/config/constants';
import { SessionStorage } from './storage/session';

export function getToken() {
  const token = SessionStorage.getItem(TOKEN);

  return token;
}

export const formatDate = (timestamp: string) => {
  const date = sub(new Date(timestamp), {
    hours: 3,
  });

  return format(date, 'dd/MM/yyyy HH:mm');
};

export const formatDocument = (document: string) => {
  if (document.length <= 11) {
    return validCpf.format(document);
  }

  return validCnpj.format(document);
};

export const formatCash = (value: number) =>
  value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

export const formatCnpj = (cnpj: string) => {
  const formatting = cnpj.replace('/', '%2F');

  return formatting;
};

export const transformObjectToQueryParams = (object: any) =>
  Object.entries(object)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

export const dayToAbbrev: any = {
  0: 'Domingo',
  1: 'Segunda-feira',
  2: 'Terça-feira',
  3: 'Quarta-feira',
  4: 'Quinta-feira',
  5: 'Sexta-feira',
  6: 'Sábado',
};

export const frequency: any = {
  day: 'diária',
  month: 'mensal',
  week: 'semanal',
};

export const getHour = (params: { hour: string }[]) => {
  const backup = [...params];

  const lastHour = backup.pop();

  if (!lastHour) {
    return '';
  }

  const lastHourFormatted = lastHour.hour.substring(0, 5).split(':').join('h');

  if (!backup.length) {
    return lastHourFormatted;
  }

  const hoursFormatted = backup
    .map((param) => param.hour.substring(0, 5))
    .map((hour) => hour.split(':').join('h'))
    .join(', ');

  return `Lives todos os dias às: ${hoursFormatted} e ${lastHourFormatted}`;
};

export function calendarText(recurrence: string, value: any) {
  const date = format(new Date(value.day), 'dd/MM/yyyy');
  const day = format(new Date(value.day), 'dd');
  const hour = value.hour.substring(0, 5);
  const abbrev = dayToAbbrev[value.day_of_week];

  if (!recurrence) {
    return `Esta agendado para ${abbrev}, dia ${date} às ${hour}`;
  }

  switch (value.frequency) {
    case 'day':
      return `Esta agenda ocorre diariamente às ${hour}`;
    case 'month':
      return `Esta agenda ocorre todo dia ${day} do mês`;
    case 'week':
      return `Esta agenda ocorre todo(a) ${abbrev} às ${hour}`;
    default:
      return `Esta agendado para ${abbrev}, dia ${date} às ${hour}`;
  }
}

export function getColorMessage(frequencyString: string) {
  switch (frequencyString) {
    case 'day':
      return 'purple';
    case 'month':
      return 'orange';
    case 'week':
      return 'pink';
    default:
      return 'blue';
  }
}
