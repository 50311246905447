import styled from 'styled-components';

export const BannerContainer = styled.div`
  width: 100%;
  min-height: 150px;
  height: auto;
  border-radius: ${({ theme }) => theme.styles.spacing.base};
  color: ${({ theme }) => theme.styles.colors.black.normal};

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    max-height: 100px;
    border-radius: 50%;
  }

  h1 {
    letter-spacing: 0.5px;
  }

  p {
    margin-top: 1vh;
    letter-spacing: 0.5px;
    font-size: ${({ theme }) => theme.styles.font.default};
  }

  div {
    width: 90%;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column-reverse;

    h1 {
      text-align: center;
      margin-top: 10px;
    }
  }
`;
