import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  AUTHENTICATION,
  FETCH_AUTH,
} from '../../../infra/redux/authentication/constants';
import { auth, user } from '../../../infra/http/web-service';
import { SESSION_AUTH, TOKEN } from '../../../main/config/constants';
import { SessionStorage } from '../../../utils/storage/session';

export const authentication =
  (params: any, history: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_AUTH });
    try {
      const { data: response } = await auth.authentication(params);

      SessionStorage.setItem(TOKEN, response.payload.access_token);

      const { data } = await user.listMe();

      SessionStorage.setItem(SESSION_AUTH, {
        ...data.payload,
        routes: response.payload.routes,
      });

      dispatch({
        type: AUTHENTICATION,
        payload: { ...data.payload, routes: response.payload.routes },
      });

      const mainRoute = response.payload?.routes.find(
        (value: any) => !!value.first
      );

      history.push(mainRoute ? mainRoute.path : '/dashboard');
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_AUTH });
    }
  };
