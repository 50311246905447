import * as yup from 'yup';
import { string, url, uuid } from '../..';

export const createCourseSchema = yup.object().shape({
  name: string('O nome')
    .max(45, 'O nome pode que ter no máximo 45 caracteres')
    .required('O nome é obrigatório'),
  description: string('A descrição')
    .max(500, 'A descrição pode que ter no máximo 500 caracteres')
    .required('A descrição é obrigatória'),
  link: yup.lazy((value) => {
    if (!value) {
      return yup.string().nullable(true).notRequired();
    }

    return url.required('O link do vídeo é obrigatório');
  }),
  local: string('O local')
    .max(100, 'O local pode que ter no máximo 100 caracteres')
    .required('O local é obrigatório'),
  date: string('A data')
    .max(100, 'A data pode que ter no máximo 100 caracteres')
    .required('A data é obrigatória'),
  course_type_id: uuid('O tipo de curso').required(
    'O tipo de curso é obrigatório'
  ),
  video: yup.lazy((value) => {
    if (!value) {
      return yup.string().nullable(true).notRequired();
    }

    return url.required('O link do vídeo é obrigatório');
  }),
});
