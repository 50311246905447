import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;

  padding: 20px;
  box-sizing: border-box;

  overflow-y: scroll;

  h2 {
    line-height: 1.5em;
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: lighter;

    font-size: ${({ theme }) => theme.styles.font.medium};
  }
`;

export const Divisor = styled.div`
  width: 100%;
  height: 500px;
  min-height: 500px;
  margin: 30px 0;

  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
    height: auto;
  }
`;

export const GraphicContainer = styled.div`
  width: 65%;
  height: 100%;
  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: ${({ theme }) => theme.styles.spacing.base};
  position: relative;
  padding: 10px;
  box-shadow: 0.5px 0.5px 10px ${({ theme }) => theme.styles.colors.gray.light};
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    height: 400px;
  }
`;
