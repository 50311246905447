import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  LIST_ALL_CALENDAR,
  FETCH_CALENDAR,
} from '../../../infra/redux/calendar/constants';
import { calendar } from '../../../infra/http/web-service';

export const listCalendar = (params: any) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_CALENDAR });
  try {
    const { data } = await calendar.listAll(params);

    dispatch({
      type: LIST_ALL_CALENDAR,
      payload: data.payload,
    });
  } catch (error: any) {
    requestError(error);
  } finally {
    dispatch({ type: FETCH_CALENDAR });
  }
};
