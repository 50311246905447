import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { BsFillSendFill } from 'react-icons/bs';
import { BiSolidRightArrow, BiSolidLeftArrow } from 'react-icons/bi';
import {
  Button,
  Skeleton,
  Stack,
  Select,
  Text,
  ButtonGroup,
  IconButton,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import {
  Container,
  Header,
  FiltersContainer,
  FilterItems,
  Body,
  Card,
  CardBody,
  CardFooter,
  Footer,
  Badge,
} from './styled';
import {
  deleteNotification,
  listNotifications,
} from '../../../../data/usecases/notifications';
import Paragraph from '../../../components/paragraph';
import {
  getColorNotification,
  getStringValue,
  getIconsNotification,
} from '../../../../utils/notification';
import DrawerContainer from './drawer';

const Notifications: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [status, setStatus] = useState('');
  const [notificationSelected, setNotificationSelected] = useState(null);

  useEffect(() => {
    listAllNotifications(0);
  }, []);

  const listAllNotifications = (page: number) => {
    dispatch(listNotifications({ page, limit: 10, status }));
  };

  const onFinishDelete = () => {
    onClose();
    listAllNotifications(notifications?.pagination?.current_page || 0);
  };

  const removeMessage = (id: string) => {
    dispatch(
      deleteNotification(
        {
          id,
        },
        onFinishDelete
      )
    );
  };

  const setNewStatus = (event: any) => {
    setStatus(event.target.value);
    dispatch(
      listNotifications({ page: 0, limit: 10, status: event.target.value })
    );
  };

  const setValueSelected = (item: any) => {
    setNotificationSelected(item);
    onOpen();
  };

  return (
    <Container>
      <Header>
        <Head title="Notificações" icon={<BsFillSendFill />} />

        <FiltersContainer>
          <FilterItems>
            <p>Filtrar por:</p>
            <Select
              defaultValue="active"
              variant="filled"
              placeholder="Todos"
              onChange={setNewStatus}
            >
              <option value="created">Em espera</option>
              <option value="recurrence">Recorrente</option>
              <option value="notified">Já notificado</option>
              <option value="cancel">Cancelado</option>
            </Select>
          </FilterItems>

          <Button
            leftIcon={<MdAdd />}
            ringColor="blue.500"
            colorScheme="blue"
            variant="ghost"
            onClick={() => {
              history.push('/notificacoes/cadastro');
            }}
          >
            Adicionar
          </Button>
        </FiltersContainer>
      </Header>
      <Body>
        {notifications.data ? (
          notifications.data.map((value: any) => (
            <Card
              colorType={getColorNotification(value.status)}
              key={value.external_id}
            >
              <Badge colorType={getColorNotification(value.status)}>
                <Icon as={getIconsNotification(value.status)} />
                {getStringValue(value.status)}
              </Badge>

              <Stack width="100%">
                <CardBody>
                  <Paragraph
                    align="left"
                    fontSize="default"
                    label="Agendamento"
                  >
                    {value.recurrence
                      ? `Todos os dias às ${value.hour.substring(0, 5)}`
                      : `${format(
                          new Date(value.day),
                          'dd/MM/yyyy'
                        )} às ${value.hour.substring(0, 5)}`}
                  </Paragraph>

                  <Paragraph align="left" fontSize="default" label="Nome">
                    {value.name}
                  </Paragraph>
                </CardBody>

                <CardFooter>
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => setValueSelected(value)}
                  >
                    Visualizar
                  </Button>
                </CardFooter>
              </Stack>
            </Card>
          ))
        ) : (
          <Stack spacing="20px">
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton
                borderRadius="md"
                key={index}
                startColor="blue.500"
                endColor="blue.800"
                height="20px"
              />
            ))}
          </Stack>
        )}
      </Body>
      <Footer>
        <Paragraph
          align="left"
          fontSize="default"
          label={`Items: ${notifications?.pagination?.total_items || 0}`}
        />

        <ButtonGroup>
          <IconButton
            icon={<BiSolidLeftArrow />}
            colorScheme="blue"
            aria-label="Left"
            disabled={notifications?.pagination?.current_page <= 0}
            onClick={() =>
              listAllNotifications(notifications.pagination.current_page - 1)
            }
          />

          <IconButton
            icon={<BiSolidRightArrow />}
            colorScheme="blue"
            aria-label="Right"
            disabled={
              notifications?.pagination?.current_page >=
              notifications?.pagination?.limit_page
            }
            onClick={() =>
              listAllNotifications(notifications.pagination.current_page + 1)
            }
          />
        </ButtonGroup>
      </Footer>

      <DrawerContainer
        isOpen={isOpen}
        onClose={onClose}
        notification={notificationSelected}
        removeNotification={removeMessage}
      />
    </Container>
  );
};

export default Notifications;
