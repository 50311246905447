import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from './styled';
import Paragraph from '../../../../components/paragraph';
import { useAppDispatch } from '../../../../../data/hooks/redux';
import { registerVideos } from '../../../../../data/usecases/channels/register-videos';
import { refreshChannels } from '../../../../../data/usecases/channels/update-channels';

export type ModelDto = {
  isOpen: boolean;
  onClose: () => void;
  channelId: string | null;
  onDelete: () => void;
};

const ModalComponent: React.FC<ModelDto> = ({
  isOpen,
  onClose,
  onDelete,
  channelId,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [configStatus, setConfigStatus] = useState({
    register: false,
    sync: false,
    delete: false,
  });

  const pushToUpdate = () => {
    history.push(`/youtube/atualizar/${channelId}`);
  };

  const onLoadingClose = (name: string) => {
    setConfigStatus((value) => ({
      ...value,
      [name]: false,
    }));
  };

  const onRefresh = () => {
    setConfigStatus((value) => ({
      ...value,
      sync: true,
    }));

    if (!channelId) {
      return;
    }

    dispatch(refreshChannels(channelId, () => onLoadingClose('sync')));
  };

  const onRegister = () => {
    setConfigStatus((value) => ({
      ...value,
      register: true,
    }));

    if (!channelId) {
      return;
    }

    dispatch(registerVideos(channelId, () => onLoadingClose('register')));
  };

  return (
    <Modal isCentered size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Informações</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container>
            <Paragraph align="left" fontSize="small" label="Vídeos">
              Busque os últimos 200 vídeos para preencher o canal, este fluxo é
              usado somente em canais novos.
            </Paragraph>

            <div>
              <Button
                variant="solid"
                colorScheme="blue"
                isLoading={configStatus.register}
                onClick={onRegister}
              >
                Registrar
              </Button>
            </div>
          </Container>
          <hr />
          <Container>
            <Paragraph align="left" fontSize="small" label="Configuração">
              Atualize ou configure os horários de lives e as notificações.
            </Paragraph>
            <div>
              <Button
                variant="solid"
                colorScheme="orange"
                onClick={pushToUpdate}
              >
                Atualizar
              </Button>
            </div>
          </Container>
          <hr />
          <Container>
            <Paragraph align="left" fontSize="small" label="Sincronização">
              Sincronize com os vídeos do youtube, caso tenha algum vídeo
              deletado.
            </Paragraph>
            <div>
              <Button
                variant="solid"
                colorScheme="yellow"
                isLoading={configStatus.sync}
                onClick={onRefresh}
              >
                Sincronizar
              </Button>
            </div>
          </Container>
          <hr />
          <Container>
            <div>
              <Button
                variant="solid"
                colorScheme="red"
                isLoading={configStatus.delete}
                onClick={onDelete}
              >
                Excluir canal
              </Button>
            </div>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
