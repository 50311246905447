import html2canvas from 'html2canvas';
import JsToPdf from 'jspdf';
import { toast } from 'react-toastify';

export const screenPrinting = (fileName: string, id = 'print') => {
  const element = document.getElementById(id);

  if (!element) {
    toast.error('Não foi possível baixar o pdf');
    return;
  }

  const topLeftMargin = 20;
  const pdfWidth = element.offsetWidth;
  const pdfHeight = element.offsetHeight;

  const width = pdfWidth < 450 ? 450 : pdfWidth;
  const height = pdfHeight < 600 ? 600 : pdfHeight;

  const pdf = new JsToPdf({
    format: [width, height],
    unit: 'px',
  });

  html2canvas(element, {
    allowTaint: true,
    scale: 3,
  }).then((canvas) => {
    const imgData = canvas.toDataURL('image/jpeg');

    pdf.addImage(imgData, 'JPG', topLeftMargin, topLeftMargin, width / 1.1, 0);

    pdf.save(`${fileName}`);
  });
};
