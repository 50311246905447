import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  CreateNotifications,
  ListAllNotifications,
} from '../../presentation/pages';

const NotificationsRoute: React.FC<any> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/`} exact component={ListAllNotifications} />

    <Route
      path={`${match.url}/cadastro`}
      exact
      component={CreateNotifications}
    />
  </Switch>
);

export default NotificationsRoute;
