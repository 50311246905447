import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
} from '@chakra-ui/react';
import React from 'react';

export type ModelDto = {
  isOpen: boolean;
  onClose: () => void;
  setProductType: (value: string) => void;
  onCreateProduct: () => void;
};

const ModalComponent: React.FC<ModelDto> = ({
  isOpen,
  onClose,
  setProductType,
  onCreateProduct,
}) => (
  <Modal isCentered isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Formulário</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text marginBottom={5}>Crie um novo tipo para os seus produtos:</Text>
        <Input
          name="name"
          onChange={(event) => setProductType(event.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={onClose}>
          Fechar
        </Button>
        <Button onClick={onCreateProduct}>Criar</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default ModalComponent;
