import { FaClock } from 'react-icons/fa';
import { FaRepeat, FaCircleCheck } from 'react-icons/fa6';
import { MdCancel } from 'react-icons/md';

export const getStringValue = (type: string) => {
  const object: any = {
    created: 'Em espera',
    recurrence: 'Recorrente',
    notified: 'Notificado',
    cancel: 'Cancelado',
  };

  return object[type] || 'Sem informação';
};

export const getColorNotification = (type: string) => {
  const object: any = {
    created: 'orange',
    recurrence: 'blue',
    notified: 'green',
    cancel: 'red',
  };

  return object[type] || 'blue';
};

export const getIconsNotification = (type: string) => {
  const object: any = {
    created: FaClock,
    recurrence: FaRepeat,
    notified: FaCircleCheck,
    cancel: MdCancel,
  };

  return object[type];
};

export const getSpecialCondition = (special: string) => {
  const object: any = {
    birthday: 'Usuários que fazem aniversário no dia',
    batch: 'Lista de dois ou mais usuários',
  };

  return object[special] || 'Sem informação';
};
