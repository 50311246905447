import React, { useEffect, useState } from 'react';
import {
  BiSolidMessageSquareDetail,
  BiSolidRightArrow,
  BiSolidLeftArrow,
} from 'react-icons/bi';
import {
  Button,
  Skeleton,
  Stack,
  ButtonGroup,
  IconButton,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import Paragraph from '../../components/paragraph';
import { useAppDispatch, useAppSelector } from '../../../data/hooks/redux';
import Head from '../../components/header';
import {
  Container,
  Header,
  Body,
  Card,
  CardBody,
  CardFooter,
  Footer,
  Badge,
  FilterItems,
  FiltersContainer,
} from './styled';
import { listMessages } from '../../../data/usecases/messages/list-all-message';
import { getColorMessage, getStringValue } from '../../../utils/messages';
import DrawerContainer from './drawer';
import {
  deleteMessage,
  listMessageById,
} from '../../../data/usecases/messages';
import { formatDate } from '../../../utils';

const Messages: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.messages);
  const [type, setType] = useState('');

  useEffect(() => {
    listAllMessages(0, type);
  }, []);

  const listAllMessages = (page: number, name: string) => {
    dispatch(listMessages({ page, limit: 10, type: name }));
  };

  const onFinishDelete = () => {
    const page = messages?.pagination?.current_page || 0;
    onClose();
    listAllMessages(page, type);
  };

  const setNewStatus = (event: any) => {
    const page = messages?.pagination?.current_page || 0;
    const newType = event.target.value;

    setType(event.target.value);
    listAllMessages(page, newType);
  };

  const listDetails = (id: string) => {
    dispatch(
      listMessageById({
        id,
      })
    );
    onOpen();
  };

  const removeMessage = (id: string) => {
    dispatch(
      deleteMessage(
        {
          id,
        },
        onFinishDelete
      )
    );
  };

  return (
    <Container>
      <Header>
        <Head title="Minhas mensagens" icon={<BiSolidMessageSquareDetail />} />

        <FiltersContainer>
          <FilterItems>
            <p>Filtrar por:</p>
            <Select defaultValue="" variant="filled" onChange={setNewStatus}>
              <option value="">Tudo</option>
              <option value="PRAYERS">Orações</option>
              <option value="DREAMS">Sonhos</option>
              <option value="TESTIMONIALS">Testemunhos</option>
            </Select>
          </FilterItems>

          <Paragraph align="right" fontSize="default" label="Total de itens">
            {messages?.pagination?.total_items || 0}
          </Paragraph>
        </FiltersContainer>
      </Header>
      <Body>
        {messages.data ? (
          messages.data.map((value: any) => (
            <Card
              colorType={getColorMessage(value.type.type)}
              key={value.message.external_id}
            >
              <Badge colorType={getColorMessage(value.type.type)}>
                {getStringValue(value.type.type)}
              </Badge>

              <Stack width="100%">
                <CardBody>
                  <Paragraph
                    align="left"
                    fontSize="default"
                    label="Enviado por"
                  >
                    {value.user.name}
                  </Paragraph>

                  <Paragraph
                    align="left"
                    fontSize="default"
                    label="Data de criação"
                  >
                    {formatDate(value.message.created_at)}
                  </Paragraph>
                </CardBody>

                <CardFooter>
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => listDetails(value.message.external_id)}
                  >
                    Visualizar
                  </Button>
                </CardFooter>
              </Stack>
            </Card>
          ))
        ) : (
          <Stack spacing="20px">
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton
                borderRadius="md"
                key={index}
                startColor="blue.500"
                endColor="blue.800"
                height="20px"
              />
            ))}
          </Stack>
        )}
      </Body>
      <Footer>
        <Paragraph align="left" fontSize="default" label="Página">
          {messages?.pagination?.current_page + 1 || 0} de{' '}
          {messages?.pagination?.limit_page || 0}
        </Paragraph>

        <ButtonGroup>
          <IconButton
            icon={<BiSolidLeftArrow />}
            colorScheme="blue"
            aria-label="Left"
            disabled={messages?.pagination?.current_page <= 0}
            onClick={() =>
              listAllMessages(messages.pagination.current_page - 1, type)
            }
          />

          <IconButton
            icon={<BiSolidRightArrow />}
            colorScheme="blue"
            aria-label="Right"
            disabled={
              messages?.pagination?.current_page >=
              messages?.pagination?.limit_page
            }
            onClick={() =>
              listAllMessages(messages.pagination.current_page + 1, type)
            }
          />
        </ButtonGroup>
      </Footer>

      <DrawerContainer
        isOpen={isOpen}
        onClose={onClose}
        removeMessage={removeMessage}
      />
    </Container>
  );
};

export default Messages;
