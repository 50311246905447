import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  LIST_PRODUCT_BY_ID,
  FETCH_PRODUCT,
} from '../../../infra/redux/products/constants';
import { product } from '../../../infra/http/web-service';

export const listProductById = (params: any) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_PRODUCT });
  try {
    const { data } = await product.listById(params.id);

    dispatch({
      type: LIST_PRODUCT_BY_ID,
      payload: data.payload,
    });
  } catch (error: any) {
    requestError(error);
  } finally {
    dispatch({ type: FETCH_PRODUCT });
  }
};
