export const getNotificationCapability = (settings: any) => {
  if (settings?.receive_all_notification === true) {
    return 'Todas as notificações estão ativadas';
  }

  if (settings.receive_notification_live === true) {
    return 'Somente as notificações de lives estão ativas';
  }

  if (settings.receive_notification_message === true) {
    return 'Somente as notificações personalizadas estão ativas';
  }

  return 'Nenhuma notificação está ativada';
};
