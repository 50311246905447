import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  margin: 15px 0;

  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 20px;
    width: 100%;
    height: auto;
  }
`;

export const Main = styled.div`
  width: 99%;
  height: 200px;

  padding: 20px;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: ${({ theme }) => theme.styles.spacing.base};

  .divisor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }

  .divisor + .divisor {
    margin-top: 20px;
  }

  h3 {
    line-height: 1.5em;
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: lighter;

    font-size: ${({ theme }) => theme.styles.font.default};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    height: auto;

    .divisor {
      flex-direction: column;
    }
  }
`;
