import styled from 'styled-components';
import { Theme } from '../../../../../main/style/theme';

export const AutoLayout = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;

  position: relative;

  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    line-height: 1.5em;
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: bolder;
    font-size: ${({ theme }) => theme.styles.font.default};
  }
`;

export const Badge = styled.div<{ colorType: keyof Theme['styles']['colors'] }>`
  padding: 5px;
  border-radius: ${({ theme }) => theme.styles.spacing.short};

  display: flex;
  align-items: center;

  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: lighter;
  font-size: ${({ theme }) => theme.styles.font.small};

  background-color: ${({ colorType, theme }) =>
    theme.styles.colors[colorType]?.medium
      ? theme.styles.colors[colorType].medium
      : '#000000'};

  color: ${({ theme }) => theme.styles.colors.white.medium};

  svg {
    margin-right: 5px;
  }
`;

export const Example = styled.div`
  width: 100%;
  height: auto;

  padding: 20px;
  box-sizing: border-box;

  border: 1px solid ${({ theme }) => theme.styles.colors.gray.light}55;
  border-radius: ${({ theme }) => theme.styles.spacing.base};
  background-color: #cccccc22;

  img {
    width: 40px;
    height: 40px;
    border-radius: ${({ theme }) => theme.styles.spacing.short};
  }
`;

export const ModelContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;

  padding: 10px;
  box-sizing: border-box;

  border: 1px solid ${({ theme }) => theme.styles.colors.gray.light}55;
  border-radius: ${({ theme }) => theme.styles.spacing.base};

  img {
    height: 90%;
    border-radius: ${({ theme }) => theme.styles.spacing.short};
    margin-right: 10px;
  }
`;

export const ShipmentsContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 10px 0;

  padding: 10px;
  box-sizing: border-box;

  border: 1px solid ${({ theme }) => theme.styles.colors.gray.light}55;
  border-radius: ${({ theme }) => theme.styles.spacing.base};

  img {
    height: 90%;
    border-radius: ${({ theme }) => theme.styles.spacing.short};
    margin-right: 10px;
  }
`;
