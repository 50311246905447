import React, { useCallback, useState } from 'react';
import { FaTicketAlt } from 'react-icons/fa';
import { QrReader } from 'react-qr-reader';
import { Spinner, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import Head from '../../../components/header';
import Search from '../../../components/search';
import Paragraph from '../../../components/paragraph';
import * as Button from '../../../components/button';

import DrawerComponent from './drawer';
import {
  Container,
  Grid,
  Card,
  QrCodeContainer,
  Table,
  TableItem,
  Badge,
} from './styled';

import { participants } from '../../../../infra/http/web-service';
import { getColorStatus, getStatus } from '../../../../utils/tickets';

export type Props = {
  match: {
    params: {
      id: string;
    };
  };
  location: {
    state: any;
  };
};

const details: React.FC<Props> = (props) => {
  const [value, setValue] = useState('Procurando...');
  const [isLoading, setLoading] = useState(true);
  const [isLoadingTickets, setLoadingTickets] = useState(false);
  const [isLoadingCheckIn, setLoadingCheckIn] = useState(false);
  const [ticket, setTicket] = useState({});
  const [allTicket, setAllTickets] = useState<any[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const clear = () => {
    setValue('Procurando...');
    setLoading(true);
    setTicket({});
  };

  const listTicketByDocument = useCallback(async (document) => {
    setLoadingTickets(true);
    try {
      const { data } = await participants.listByDocument(
        document,
        props.match.params.id
      );

      if (!data.payload) {
        return;
      }

      setAllTickets(data.payload);
    } catch (error) {
      setAllTickets([]);
      toast.error('Este documento não tem ingressos cadastrados');
    }
    setLoadingTickets(false);
  }, []);

  const listTicket = useCallback(
    async (participant?: string) => {
      try {
        const { data } = await participants.listById(
          participant || value,
          props.match.params.id
        );

        if (!data.payload) {
          return;
        }

        setTicket(data.payload);
        onOpen();
      } catch (error) {
        toast.error('O ticket não foi encontrado na nossa busca');
      }
    },
    [value]
  );

  const checkInTicket = useCallback(async () => {
    setLoadingCheckIn(true);
    try {
      const { data } = await participants.checkIn(value, props.match.params.id);

      toast.success(data.message || 'Ticket liberado!');
      clear();
      onClose();
    } catch (error: any) {
      toast.error(
        error?.response?.message || 'Não conseguimos liberar o ticket'
      );
    }
    setLoadingCheckIn(false);
  }, [ticket]);

  return (
    <Container>
      <Head
        title={props.location?.state?.course?.name || 'Detalhes do evento'}
        icon={<FaTicketAlt />}
      />
      <Grid>
        <Card>
          <QrCodeContainer>
            <QrReader
              onResult={(result) => {
                if (result) {
                  setValue(result.getText());
                  setLoading(false);
                }
              }}
              constraints={{ facingMode: 'environment' }}
              videoStyle={{ height: '100%' }}
            />
          </QrCodeContainer>

          <Paragraph align="left" fontSize="default" label="Ticket:">
            {value}
          </Paragraph>
          <Button.Primary
            backgroundColor="blue"
            color="white"
            label="Escanear"
            type="button"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={listTicket}
          />
        </Card>
        <Card>
          <Paragraph
            align="left"
            fontSize="default"
            label="Busca por documento"
          >
            Para realizar a busca por cpf utilize o campo abaixo para pesquisar
            o ingresso.
          </Paragraph>

          <Search
            placeholder="Digite o cpf sem formatação"
            onClick={listTicketByDocument}
          />

          <Table>
            {isLoadingTickets ? (
              <Spinner colorScheme="blue" />
            ) : (
              allTicket.map((ticketValue) => (
                <TableItem key={ticketValue?.participant?.external_id}>
                  <p>{ticketValue?.participant?.name}</p>
                  <div className="badge">
                    <Badge
                      colorType={getColorStatus(
                        ticketValue.participant?.status
                      )}
                    >
                      {getStatus(ticketValue.participant?.status)}
                    </Badge>
                  </div>

                  <div className="button">
                    <Button.Primary
                      backgroundColor="gray"
                      color="white"
                      label="Abrir"
                      type="button"
                      onClick={() => {
                        listTicket(ticketValue?.participant?.external_id);
                      }}
                    />
                  </div>
                </TableItem>
              ))
            )}
          </Table>
        </Card>
      </Grid>

      <DrawerComponent
        clear={clear}
        isOpen={isOpen}
        onClose={onClose}
        ticket={ticket}
        checkInTicket={checkInTicket}
        isLoading={isLoadingCheckIn}
      />
    </Container>
  );
};

export default details;
