import React, { memo } from 'react';

import { Container, Error, Text, Label } from './styled';
import { SwitchDTO } from '../../../domain/components/switch-dto';

const SwitchComponent = React.forwardRef<HTMLInputElement, SwitchDTO>(
  (props, ref) => {
    const { name, label, error, enabled, ...rest } = props;

    return (
      <Container>
        <div>
          <Text>{label}</Text>

          <Label>
            <input
              type="checkbox"
              name={name}
              ref={ref}
              {...rest}
              defaultChecked={enabled}
            />
            <span />
          </Label>
        </div>

        {error && <Error>{error.message}</Error>}
      </Container>
    );
  }
);

export default memo(SwitchComponent);
