import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Body = styled.div`
  width: 100%;
  height: calc(100% - 70px);

  gap: 20px;
  padding: 20px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  overflow-y: scroll;
`;

export const Card = styled.div`
  width: 450px;
  min-height: 600px;
  height: auto;
  border-radius: 16px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 100%;
  }
`;

export const Title = styled.h3`
  width: 100%;
  text-align: left;
  font-weight: bolder;
  text-align: center;

  font-size: ${({ theme }) => theme.styles.font.medium};

  margin-bottom: 20px;
`;

export const Image = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: contain;

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 80%;
  }
`;

export const PlatformContainer = styled.div`
  width: 100%;
  margin: 15px 0px;
`;
