import styled from 'styled-components';
import { Params } from '.';

export const Div = styled.div`
  margin: 10px 0;
`;

export const Text = styled.p<Params>`
  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: ${({ align }) => align};
  font-weight: lighter;

  font-size: ${({ fontSize, theme }) => theme.styles.font[fontSize]};
`;

export const Label = styled.p<Params>`
  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: ${({ align }) => align};
  font-weight: bold;

  font-size: ${({ fontSize, theme }) => theme.styles.font[fontSize]};
`;
