import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ImBooks } from 'react-icons/im';
import { Box, Stack, StackDivider, useDisclosure } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import Paragraph from '../../../components/paragraph';
import {
  Container,
  Header,
  Grid,
  FlexContainer,
  Section,
  Img,
  HeaderItems,
  ButtonContainer,
} from './styled';

import { listProductById } from '../../../../data/usecases/products/list-by-id-product';
import { formatCash, formatDate } from '../../../../utils';
import * as Button from '../../../components/button';
import ModalComponent from './modal';
import { deleteProduct } from '../../../../data/usecases/products/delete-product';

export type Props = {
  match: {
    params: {
      id: string;
    };
  };
};

const Products: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const product = useAppSelector((state) => state.product);

  const listProduct = () => {
    dispatch(
      listProductById({
        id: props.match.params.id,
      })
    );
  };

  const removeProduct = () => {
    dispatch(
      deleteProduct(
        {
          id: props.match.params.id,
        },
        history
      )
    );
  };

  const pushToDetails = () => {
    history.push(`/estoques/atualizar/${props.match.params.id}`);
  };

  useEffect(() => {
    listProduct();
  }, []);

  return (
    <Container>
      <Head title="Detalhes" icon={<ImBooks />} />

      <Grid>
        {product.product && (
          <Header>
            <Img src={product.product.image.url} alt="Andando no profético" />

            <HeaderItems>
              <h2>{product.product.product.name}</h2>

              <Paragraph fontSize="default" label="Descrição" align="left">
                {product.product.product.description}
              </Paragraph>

              <Paragraph fontSize="default" label="Valor" align="left">
                {formatCash(+product.product.product.value / 100)}
              </Paragraph>

              <a
                href={product.product.product.link}
                target="_blank"
                rel="noreferrer"
              >
                <Button.Primary
                  backgroundColor="blue"
                  color="white"
                  label="Página de acesso"
                  type="button"
                />
              </a>
            </HeaderItems>
          </Header>
        )}

        <FlexContainer>
          <Section scroll>
            {product.product && (
              <>
                <h2>Informações</h2>

                <Stack divider={<StackDivider />} spacing="4">
                  <Box>
                    <Paragraph
                      fontSize="default"
                      label="Ativo no aplicativo"
                      align="left"
                    >
                      {product.product?.product?.enabled ? 'Sim' : 'Não'}
                    </Paragraph>
                  </Box>
                  <Box>
                    <Paragraph
                      fontSize="default"
                      label="Categoria"
                      align="left"
                    >
                      {product.product?.type?.type}
                    </Paragraph>
                  </Box>
                  <Box>
                    <Paragraph
                      fontSize="default"
                      label="Data de criação"
                      align="left"
                    >
                      {formatDate(product.product?.product?.created_at)}
                    </Paragraph>
                  </Box>
                </Stack>
              </>
            )}
          </Section>

          <Section scroll={false}>
            {product.product && (
              <ButtonContainer>
                <h2>Ações</h2>

                <Button.Primary
                  backgroundColor="orange"
                  color="black"
                  label="Atualizar"
                  type="button"
                  onClick={pushToDetails}
                />
                <Button.Primary
                  backgroundColor="red"
                  color="white"
                  label="Deletar"
                  type="button"
                  onClick={onOpen}
                />
              </ButtonContainer>
            )}
          </Section>
        </FlexContainer>
      </Grid>

      <ModalComponent
        removeProducts={removeProduct}
        isOpen={isOpen}
        isLoading={product.isFetch}
        onClose={onClose}
      />
    </Container>
  );
};

export default Products;
