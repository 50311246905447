import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Tickets, TicketsDetails } from '../../presentation/pages';

const TicketsRoute: React.FC<any> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/`} exact component={Tickets} />

    <Route
      path={`${match.url}/detalhes/:id`}
      exact
      component={TicketsDetails}
    />
  </Switch>
);

export default TicketsRoute;
