import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  FETCH_MESSAGE,
  DELETE_MESSAGE,
} from '../../../infra/redux/messages/constants';
import { message } from '../../../infra/http/web-service';

export const deleteMessage =
  (params: any, onClose: () => void) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MESSAGE });
    try {
      const { data } = await message.delete(params.id);

      dispatch({ type: DELETE_MESSAGE, payload: { id: params.id } });

      toast.success(data.message);
      onClose();
    } catch (error: any) {
      requestError(error);
    } finally {
      dispatch({ type: FETCH_MESSAGE });
    }
  };
