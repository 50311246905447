import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 10%;
`;

export const Form = styled.form`
  width: 100%;
  height: 90%;
  overflow: hidden;
  padding: 20px;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: 16px;
`;

export const Divisor = styled.div<{ width: string; scroll: boolean }>`
  width: 100%;
  height: ${({ width }) => width};

  gap: 20px;

  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'unset')};
    padding: 10px;
  }

  li {
    font-weight: lighter;

    font-size: ${({ theme }) => theme.styles.font.default};
  }
`;

export const Box = styled.div<{ scroll: boolean }>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'unset')};

  ul {
    margin-left: 20px;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    height: 100%;
    overflow-y: unset;
  }
`;

export const Paragraph = styled.p`
  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: lighter;

  font-size: ${({ theme }) => theme.styles.font.default};
`;

export const Example = styled.div`
  width: 100%;
  height: auto;

  margin-bottom: 20px;

  padding: 20px;
  box-sizing: border-box;

  border: 1px solid ${({ theme }) => theme.styles.colors.gray.light}55;
  border-radius: ${({ theme }) => theme.styles.spacing.base};
  background-color: #cccccc22;

  img {
    width: 40px;
    height: 40px;
    border-radius: ${({ theme }) => theme.styles.spacing.short};
  }

  :hover {
    border-color: ${({ theme }) => theme.styles.colors.blue.medium};
    cursor: pointer;
  }

  span {
    line-height: 1.5em;
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: lighter;

    font-size: ${({ theme }) => theme.styles.font.tiny};
  }
`;
