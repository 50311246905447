import React, { useEffect, useState } from 'react';

import { Text } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Button from '../../../components/button';
import { SalesContainer, Div } from './styled';
import { graph } from '../../../../infra/http/web-service';

const Sales: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<any>({
    events: [],
  });

  const list = async () => {
    try {
      const response = await graph.listEvents();

      if (!response.data?.payload?.data?.events?.length) {
        return;
      }

      setData(response.data.payload.data);
    } catch (error) {
      toast.error('Ops, não conseguimos buscar a quantidade de produtos');
    }
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <SalesContainer>
      <div>
        <Text fontSize="larger" fontWeight="bold">
          Eventos
        </Text>
      </div>

      <div className="title">
        {data?.events?.map((value: any, index: number) => (
          <div key={index}>
            <Div key={index}>
              <img src={value.title} alt={value.name} />
              <div className="items">
                <Text fontSize="medium" fontWeight="light">
                  {value.name}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  Total de visualizações: {value.quantity}
                </Text>
              </div>
            </Div>
            <hr />
          </div>
        ))}

        <Div>
          <div className="items">
            <Text fontSize="medium" fontWeight="light">
              Total de visualizações
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              {data.count}
            </Text>
          </div>
        </Div>
      </div>

      <Button.Primary
        type="button"
        color="white"
        backgroundColor="blue"
        label="Visualizar eventos"
        onClick={() => history.push('/eventos')}
      />
    </SalesContainer>
  );
};

export default Sales;
