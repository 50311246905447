import styled from 'styled-components';

export const Container = styled.div`
  width: 40%;
  height: auto;

  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.styles.colors.blue.light}99;
  border-radius: ${({ theme }) => theme.styles.spacing.base};
  padding: 1vh;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  @media ${({ theme }) => theme.devices.tablet} {
    margin: 10px 0;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    margin: 10px 0;
  }
`;

export const Icon = styled.div`
  width: 70px;
  height: 100%;
  background-color: ${({ theme }) => theme.styles.colors.blue.bold};
  border-radius: ${({ theme }) => theme.styles.spacing.short};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80%;
    object-fit: contain;
  }
`;
