import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { notificationChannel } from '../../../../../infra/http/web-service';
import Input from '../../../../components/input';
import TextArea from '../../../../components/text-area';
import Select from '../../../../components/select';
import { createConfigurationNotificationSchema } from '../../../../../validation/forms';

export type ModelDto = {
  isOpen: boolean;
  notifications: any[];
  onClose: () => void;
  channelId: string;
  reload: () => void;
};

const ModalComponentCreate: React.FC<ModelDto> = ({
  isOpen,
  notifications,
  channelId,
  onClose,
  reload,
}) => {
  const [select, setSelect] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const props = [
      {
        externalId: 'first-notification-live',
        name: 'Primeira mensagem',
      },
      {
        externalId: 'second-notification-live',
        name: 'Segunda mensagem',
      },
      {
        externalId: 'third-notification-live',
        name: 'Terceira mensagem',
      },
    ];

    const namesToFilter = notifications.map((value) => value.alias);

    setSelect(
      props.filter((value) => !namesToFilter.includes(value.externalId))
    );
  }, [notifications]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(createConfigurationNotificationSchema),
  });

  const onSubmit = async (value: any) => {
    try {
      setLoading(true);
      await notificationChannel.create(channelId, value);

      toast.success('Notificação criada com sucesso!');
      reload();
      onClose();
    } catch (error) {
      toast.error('Ops, não foi possível criar a notificação');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nova notificação</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Título da notificação"
              type="text"
              {...register('title')}
              error={errors.title}
            />

            <TextArea
              label="Corpo da mensagem"
              {...register('body')}
              error={errors.body}
            />

            <Select
              label="Qual mensagem?"
              {...register('alias')}
              error={errors.alias}
              items={select}
            />

            <Button type="submit" colorScheme="blue" isLoading={loading}>
              Criar
            </Button>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponentCreate;
