import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StoriesPage from '../../presentation/pages/story';
import CreateStory from '../../presentation/pages/story/create';

const StoryRoute: React.FC<any> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/`} exact component={StoriesPage} />
    <Route path={`${match.url}/cadastro`} exact component={CreateStory} />

  </Switch>
);

export default StoryRoute;
