import axios, { AxiosRequestConfig } from 'axios';

const api = axios.create({
  baseURL: 'https://api.andandonoprofetico.com',
});

export const auth = {
  authentication: (params: any) => api.post('v1/authentications/user', params),
};

export const user = {
  delete: (headers: AxiosRequestConfig['headers']) =>
    api.delete('v1/users/me', {
      headers,
    }),
};
