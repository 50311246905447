import { combineReducers } from 'redux';

import { authReducer } from './authentication/reducer';
import { productsReducer } from './products/reducer';
import { channelsReducer } from './channels/reducer';
import { calendarReducer } from './calendar/reducer';
import { eventsReducer } from './events/reducer';
import { clientsReducer } from './clients/reducer';
import { productTypeReducer } from './products-type/reducer';
import { eventsTypeReducer } from './events-type/reducer';
import { messagesReducer } from './messages/reducer';
import { notificationsReducer } from './notifications/reducer';
import { ticketsReducer } from './tickets/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  product: productsReducer,
  channels: channelsReducer,
  events: eventsReducer,
  calendar: calendarReducer,
  productType: productTypeReducer,
  eventsType: eventsTypeReducer,
  clients: clientsReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  tickets: ticketsReducer,
});

export { rootReducer };
