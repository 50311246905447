import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import { LOGOUT } from '../../../infra/redux/authentication/constants';
import { SESSION_AUTH, TOKEN } from '../../../main/config/constants';
import { SessionStorage } from '../../../utils/storage/session';

export const logout = (history: any) => async (dispatch: Dispatch) => {
  try {
    SessionStorage.removeItem(TOKEN);
    SessionStorage.removeItem(SESSION_AUTH);
    dispatch({ type: LOGOUT });

    history.push('/');
  } catch (error: any) {
    requestError(error);
  }
};
