import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  CreateEvents,
  Events,
  EventsDetails,
  UpdateEvents,
} from '../../presentation/pages';

const EventsRoute: React.FC<any> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/`} exact component={Events} />

    <Route path={`${match.url}/cadastro`} exact component={CreateEvents} />

    <Route path={`${match.url}/detalhes/:id`} exact component={EventsDetails} />

    <Route path={`${match.url}/atualizar/:id`} exact component={UpdateEvents} />
  </Switch>
);

export default EventsRoute;
