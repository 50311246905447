import * as yup from 'yup';
import { string } from '../..';

export const updateConfigurationNotificationSchema = yup.object().shape({
  title: string('O título')
    .max(80, 'O título pode que ter no máximo 80 caracteres')
    .required('O título é obrigatório'),
  body: string('O corpo da mensagem')
    .max(200, 'O corpo da mensagem pode que ter no máximo 200 caracteres')
    .required('O corpo da mensagem é obrigatório'),
});
